import React, { Component } from "react";
import Conversation from "./Conversation";
import AllConversations from "./AllConversations"

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {//messageThreads[0] => ['8013499925', { messages: [<ActiveRecord::Messages>], urls: [urls] }, 'Bob Doe']
            currentConversation: [],
            currentConversationImageUrls: [],
            currentRecipient: this.findRecipientFromParam(),
            currentRecipientName: this.findRecipientNameFromParam(),
            conversationWasSetInParamater: this.wasConversationSetInParameter(),
            businessPhoneNumber: props.businessPhoneNumber,
            messageThreads: props.messageThreads,
            employeeName: props.employeeName
        }

        this.wasConversationSetInParameter = this.wasConversationSetInParameter.bind(this);
        this.setMessageThread = this.setMessageThread.bind(this);
        this.findRecipientNameFromParam = this.findRecipientNameFromParam.bind(this);
        this.addNewMessage = this.addNewMessage.bind(this);
        this.setupMessageSubscription = this.setupMessageSubscription.bind(this);
        this.receivedMessageFromActionCableSubscription = this.receivedMessageFromActionCableSubscription.bind(this);
        this.scrollToBottomOfCurrentConversation = this.scrollToBottomOfCurrentConversation.bind(this);
        this.openNewConversation = this.openNewConversation.bind(this);
        this.createLinkElement = this.createLinkElement.bind(this);
        this.setCurrentThreadViewAndImageUrls = this.setCurrentThreadViewAndImageUrls.bind(this);
    }

    componentDidMount() {
        this.setCurrentThreadViewAndImageUrls()
        this.setupMessageSubscription();
        this.setMessageContentFromParam();
    }

    // Fetching current conversation image_urls and messages
    setCurrentThreadViewAndImageUrls() {
        let currentThreadMessages = {};
        if (this.props.threadToView == null){
            currentThreadMessages =  this.props.messageThreads[0][1];
        }
        else {
            for (let i = 0; i < this.props.messageThreads.length; i++){
                let currentThread = this.props.messageThreads[i];
                let number = currentThread[0];
                if (number == this.props.threadToView){
                    currentThreadMessages =  this.props.messageThreads[i][1];
                }
            }
        }

        const { urls, messages } = currentThreadMessages;
        this.setState({
            currentConversation: messages || [],
            currentConversationImageUrls: urls || []
        })
    }

    wasConversationSetInParameter(){
        return this.props.threadToView == null
    }

    //checks if the conversation url param was set, and sets the recipient from that param, otherwise defaults to first in the list.
    findRecipientFromParam(){
        if (this.props.threadToView == null){
            return this.props.messageThreads[0][0]           
        }
        else{
            return this.props.threadToView
        }
    }

    //checks if the conversation url param was set, and sets the recipient name from that param, otherwise defaults to first in the list.
    findRecipientNameFromParam(){
        if (this.props.threadToView == null){
            return this.props.messageThreads[0][2]
        }
        else{
            $.get({
                url: '/resolve_phone',
                data: {phone: this.props.threadToView},
                success: function(data){
                    //sets the recipientName when url is set with a conversation number
                    let wasCustomer = data["customer"] != null;
                    let wasContact = data["contact"] != null;
                    let couldntResolve = data == [];
                    if (!couldntResolve){
                        if (wasCustomer){
                            var recipientNameElement = this.createLinkElement('View Customer', data["customer"]["full_name"], `/customers/${data["customer"]["id"]}`);
                            $('#convo-recipient-name').append(recipientNameElement);
                        }
                        else if (wasContact){
                            var recipientNameElement = this.createLinkElement('View Customer', data["contact"]["full_name"], `/customers/${data["contact"]["customer_id"]}`);
                            $('#convo-recipient-name').append(recipientNameElement);
                        }
                    }
                }.bind(this),
                error: function(data){
                    console.log("Error getting the recipent name from the phone number");
                }
            })
            return ""
        }
    }

    createLinkElement(title, text, href){
        var a = document.createElement('a');
        var linkText = document.createTextNode(text);
        a.appendChild(linkText);
        a.title = title;
        a.href = href;
        return a;
    }

    //if the message url param was set, it fills the message box. Useful for creating links with prefilled text messages.
    setMessageContentFromParam(){
        if (this.props.content != null){
            $('#message_to_send').val(this.props.content);
        }
    }

    //Received a new message from the application, so this callback adds the message button and notification.
    receivedMessageFromActionCableSubscription(data){
        console.log(`Received incoming message from: ${data["sent_from"]}`); //Just received a message.. We are assuming it's a received message because message.rb doesn't broadcast on actioncable when we created & sent a message out. Therefore, we know that data["to"] is who sent the message to us. 
        console.log(`content: ${data["content"]}`); //Just received a message.. We are assuming it's a received message because message.rb doesn't broadcast on actioncable when we created & sent a message out. Therefore, we know that data["to"] is who sent the message to us. 
        console.log(`to: ${data["to"]}`); //Just received a message.. We are assuming it's a received message because message.rb doesn't broadcast on actioncable when we created & sent a message out. Therefore, we know that data["to"] is who sent the message to us. 
        console.log(`sent on: ${data["sent"]}`); //Just received a message.. We are assuming it's a received message because message.rb doesn't broadcast on actioncable when we created & sent a message out. Therefore, we know that data["to"] is who sent the message to us. 
        const receivedMessage = {sent_from: data["sent_from"], to: data["to"], content: data["content"], sent: data["sent"]};
        const imageUrl = data["image_url"];
        if (this.state.currentRecipient === data["sent_from"]){
            this.setState({
                currentConversation: [ ...this.state.currentConversation, receivedMessage ],
                currentConversationImageUrls: [ ...this.state.currentConversationImageUrls, imageUrl ]
            });
            this.scrollToBottomOfCurrentConversation();//Scroll to bottom of current convo page
        }
        else{
            for(let i = 0; i < this.state.messageThreads.length; i++){
                if (this.state.messageThreads[i][0] === data["sent_from"]){

                    const messageThreads = this.state.messageThreads
                    messageThreads[i][1]['messages'] = [...messageThreads[i][1]['messages'], receivedMessage]
                    messageThreads[i][1]['urls'] = [...messageThreads[i][1]['urls'], imageUrl]
                    this.setState({
                        messageThreads: messageThreads,
                    });
                    return;
                }
            }

            //If we made it this far, we need to create a whole new thread.
            const thread = [ data["to"], { messages: [receivedMessage], urls: [imageUrl] }];
            this.setState({
                messageThreads: [...messageThreads, thread]
            })
        }
    }

    setupMessageSubscription(){
        console.log("Setting up message subscription!");
        App.messages = App.cable.subscriptions.create('MessagesChannel', {
            received: (data) => this.receivedMessageFromActionCableSubscription(data)
        });
    }
 
    //Given a conversation & recipient, we set the message thread
    setMessageThread(conversation, recipient, recipientName, urls){
        this.setState({
            currentConversation: conversation,
            currentRecipient: recipient,
            currentRecipientName: recipientName,
            currentConversationImageUrls: urls || []
        });
        $.post({
            url: '/mark_as_read.json', 
            data: {number: recipient}, 
            error: function(data){
                gritterError("Error", "Couldn't mark these sms messages as being seen")
            }
        });
        $('#chat-window').scrollTop($('#chat-window')[0].scrollHeight);//Scroll to bottom of current convo pagej
    }

    //Callback for when you want to send a message by typing in a phone number
    openNewConversation(){
        console.log("Open new conversation button clicked");
        this.setState({
            currentRecipient: "New Conversation",
            currentRecipientName: "",
            currentConversation: []
        });
    }

    //when sending a new message, this is the callback for adding the new message bubble in the conversation 
    addNewMessage(messagePayload, resetImageDataCallback){
        const { image: { imageUrl, file, id }, message } = messagePayload;

        if (isBlank(message) && isBlank(imageUrl)){ //Don't want to send a message if it's blank
            return;
        }

        let sendTo = this.state.currentRecipient != "New Conversation" ? this.state.currentRecipient : $('#new-phone-number').val();

        const messageFormData = new FormData();
        messageFormData.append('file', file)
        messageFormData.append('id', id)
        messageFormData.append('name', this.state.employeeName)
        messageFormData.append('content', message)
        messageFormData.append('to', sendTo)
        messageFormData.append('sent_from', this.state.businessPhoneNumber)

        $.ajax({
            url: '/messages.json',
            type: "POST",
            dataType: 'json',
            data: messageFormData,
            processData: false,
            contentType: false,
            success:function(data){
                const new_message = {to: this.state.currentRecipient, sent_from: this.state.businessPhoneNumber, content: message}
                this.setState({
                    currentConversation: [ ...this.state.currentConversation, new_message ],
                    currentConversationImageUrls: [ ...this.state.currentConversationImageUrls, imageUrl ],
                    currentRecipient: sendTo,
                    currentRecipientName: this.state.currentRecipientName,
                })
                resetImageDataCallback();
                $('#chat-window').scrollTop($('#chat-window')[0].scrollHeight);//Scroll to bottom of current convo page
            }.bind(this), //binding to 'this' so we can access 'this' from within the success callback.
            error: function(data){
                showError("Couldn't Send Message", data); //shows gritter errors
            }.bind(this)
        });
    }

    scrollToBottomOfCurrentConversation(){ //TODO: Replace all times we scroll to bottom with this function call for readability
        $('#chat-window').scrollTop($('#chat-window')[0].scrollHeight);
    }
    render() {        
        const { messageThreads, businessPhoneNumber } = this.props;
        //We need to show the conversation previews if they just went to messages. If they navigated to conversations with a conversation in mind, we'll hide the previews and only show the current conversation.
        let shouldHideConversation = this.state.conversationWasSetInParamater //if currentRecipient is null it wasn't set.
        return (
        <div>
            <div id="conversation-previews" 
                 style={{display: shouldHideConversation ? null : 'none'}}>
                <AllConversations 
                    messageThreads={messageThreads.filter(thread => thread[1].messages.length > 0)} //We filter this because it doesn't make sense to have an empty preview for a conversation if it doesn't have any messages in it. This mainly happens when a user specifies a conversation that hasn't had any messages sent back and forth between the business yet. This is also because our messaging components are highly dependent on each other and tightly coupled, otherwise I would prefer just rendering the single conversation component, but for now we have to render the entire messaging component starting with the messages/Index.jsx
                    businessPhoneNumber={businessPhoneNumber}
                    setMessageThreadCallback = {this.setMessageThread}
                    pageNumber = {this.props.pageNumber}>
                </AllConversations>    
            </div>
            <div 
                className="container" 
                id="current-conversation" 
                style={{display: shouldHideConversation ? 'none' : null}}>
                <div className="row">
                    <div className="col">
                        <Conversation
                            // style={{display: shouldHideConversation ? 'none' : null}}
                            context={this.state.currentConversation}
                            messagesImageUrls={this.state.currentConversationImageUrls}
                            businessPhoneNumber = {businessPhoneNumber}
                            recipient = {this.state.currentRecipient}
                            recipientName = {this.state.currentRecipientName}
                            addNewMessageCallback = {this.addNewMessage}
                            openNewConversationCallback = {this.openNewConversation}>
                        </Conversation>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default Index;