import React, { Component } from "react"

class SignaturePad extends Component {
    constructor(props){
        super(props);
    }

    componentDidMount(){
    
        // var canvas = document.getElementById("signature-pad");
        // console.log("Canvas is: " + canvas);

        // var signaturePad = new SignaturePad(canvas);
        // submittingForm = canvas.closest("form");
        
        // canvas.addEventListener('mousedown', function() {
        //     console.log("Mouse down on the canvas element");
        //     enablePayment();
        // }, false);

        // canvas.addEventListener('mouseup', function() {
        //     console.log("Mouse up on the canvas element");
        //     document.getElementById("signature-pad-value").value = signaturePad.toDataURL();
        // }, false);

        // $('button[type="submit"]').click(function(e){//here's where I could check if the signature was present, if it's not, prevent default and alert that they need to sign.
        //     document.getElementById("signature-pad-value").value = signaturePad.toDataURL();
        // })

        // if(props.signature != null)
        // {
        //     setSignatureToBeEdited(props.signature);
        // } 
        // else 
        // {
        //     disablePaymentIfNoSignature(); // call this after we set the signature
        // }

        // setSignatureToBeEdited = function(dataPoints){ //sets the signature from an invoice that's already been signed
        //     signaturePad.fromDataURL(dataPoints);
        // }

        // document.getElementById('clear-signature').addEventListener('click', function () {
        //     signaturePad.clear();
        //     document.getElementById("signature-pad-value").value = "";
        //     disablePayment(); // we don't want them to be able to pay if they just cleared the signature, because there's no signature now haha.
        // });


        // disablePaymentIfNoSignature = function(){
        //     if (signaturePad.isEmpty()){
        //         disablePayment();
        //     }
        // }

        // function disablePayment(){
        //     // document.getElementById("elavon-button").disabled=true;
        //     if(document.getElementById("payment_gateway_name").value == 'stripe'){
        //         document.getElementsByClassName("stripe-button-el")[0].disabled=true;
        //     } else{
        //         document.getElementsByClassName("ibx-pay-button")[0].disabled=true;
        //     }

        //     $('#require-signature-notice').show();
        // }

        // function enablePayment(){
        //     // document.getElementById("elavon-button").disabled=false;
        //     if(document.getElementById("payment_gateway_name").value == 'stripe'){
        //         document.getElementsByClassName("stripe-button-el")[0].disabled=false;
        //     } else{
        //         document.getElementsByClassName("ibx-pay-button")[0].disabled=false;
        //     }
        
        //     $('#require-signature-notice').hide();
        // }
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-center">
                    <div className="p-2">
                        <canvas id="signature-pad" className="signature-pad" style="border-style: solid"> </canvas>
                        <input type="hidden" id="signature-pad-value" name="signature"></input>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="p-2">
                        <label htmlFor="signature-pad"> Sign Above </label>
                    </div>
                    <div className="p-2">
                        <button id="clear-signature" type="button" className="btn btn-danger">Clear</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SignaturePad;