import React, { Component } from 'react';
import TableData from "./TableData";
import DateRangePicker from "./DateRangePicker";

class IndexPage extends Component {
    constructor(props){
        super(props);

        this.openSearchBar = this.openSearchBar.bind(this);
    }

    openSearchBar() {
        const search = prompt("Search");
        const data = document.getElementsByClassName('tdData');

        if (search != null) {
            for (let d = 0; d < data.length; d++) {
                const text = data[d].innerText.split(" ");

                for (let i = 0; i < text.length; i++) {
                    let index = text[i];
                    let splitIndex = index.split("");
                    if (index.toLowerCase().includes(search.toLowerCase())) {
                        for (let si = 0; si < index.length; si++) {
                            if (search.toLowerCase().includes(index[si].toLowerCase())) {
                                splitIndex[si] = `<mark style="padding: 0; background-color: yellow;" >${index[si]}</mark>`;
                                text[i] = splitIndex.join("");
                            }
                        }
                    }
                }

                data[d].innerHTML = text.join(" ");
            }
        }
    }

    render(){
        const {title, attributes, data, basePath, updateProduct, removeProduct, handleProductPriceSorting, handleProductNameSorting, showNewProductButton} = this.props;        
        return(
            <React.Fragment>
                <div className="d-flex justify-content-center">
                    <div className="p-3">
                        <a href={`${basePath}/new`} title="New">
                            <i className="fas fa-plus fa-2x"></i>
                        </a>
                    </div>
                    <div className="p-2">
                        <h1>{title}</h1>
                    </div>
                    <div className="p-3">
                        <a href="javascript:void(0)" onClick={this.openSearchBar} title="New">
                            <i className="fas fa-search fa-2x"></i>
                        </a>
                    </div>
                </div>
                <TableData 
                 updateProduct={updateProduct}
                 handleProductPriceSorting={handleProductPriceSorting}
                 handleProductNameSorting={handleProductNameSorting}
                 removeProduct={removeProduct}
                 title = {title}
                 attributes = {attributes}
                 data = {data}
                 basePath = {basePath}
                 showNewProductButton = {showNewProductButton}
                />
                {
                    this.props.showDatePicker && <DateRangePicker
                        startDateId={this?.props?.dateData?.startDateId}
                        endDateId={this?.props?.dateData?.endDateId}
                        startDate={this?.props?.dateData?.startDate}
                        endDate={this?.props?.dateData?.endDate}
                        path={this?.props?.dateData?.path}
                    />
                }

            </React.Fragment>
        );
    }
}

export default IndexPage;