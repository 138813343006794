import React, {Component} from "react";

class ConversationPreview extends Component{
    constructor(props){
        super(props);
        this.getUnreadIcon = this.getUnreadIcon.bind(this);
    }

    getUnreadIcon = (message)=>{
        console.log(`rendering message preview for2 ${message.has_been_read}`) 
        if (!message.has_been_read){
            return (
                <svg height="20" width="20" id={`conversation-${message.sent_from}-${message.to}`}>
                    <circle cx="10" cy="10" r="5" stroke="#358FFB" strokeWidth="1" fill="#358FFB" />
                </svg>)
        }
        else {
            return (<span></span>)
        }
    }

    render(){
        const {messageThread,
             setMessageThreadCallback,
             recipient,
             recipientName,
             urls,
             businessPhoneNumber} = this.props;
        return(
            <div className="container" 
                id={`conversation-preview-${recipient}`} 
                onClick = {() => {
                        setMessageThreadCallback(messageThread, recipient, recipientName, urls); 
                        $(`#conversation-${messageThread[0].sent_from}-${messageThread[0].to}`).hide();//Remove the unread notification when they click this convo-thread if it's there.
                        $('#conversation-previews').hide();
                        $('#current-conversation').show();
                        $('#chat-window').scrollTop($('#chat-window')[0].scrollHeight)

                        const { id } = recipientName;
                        const noImage = $('<div><span>No Image Found</span></div>');
                        $('#photos-to-attach-to-message').empty();
                        $('#photos-to-attach-to-message').append(noImage);

                        if(id){
                            $.get({
                                url: `/messages/${id}/customer_uploaded_images.json`,
                                success: function(data){
                                        if(data.length > 0){
                                            const imageContainer = $('<div/>');
                                            $('#photos-to-attach-to-message').empty();

                                            data.forEach((image)=>{
                                                imageContainer.append(
                                                    $('<img/>', {
                                                        'src': `${image['url']}`,
                                                        'style': 'width: 150px  !important; height: 150px;',
                                                        'class': 'm-1 btn p-0 modal-img',
                                                        'data-id': `${image['id']}`,
                                                        'onclick': 'imageClickHandler(event)'
                                                    })
                                                )
                                            })
                                            $('#photos-to-attach-to-message').append(imageContainer);
                                        }
                                },
                                error: function(data){
                                    console.log("error");
                                    console.log(data);
                                }
                            });
                        }
                    }
                }>
                <div className="d-flex justify-content-start">
                    <div className="p-2">
                        {this.getUnreadIcon(messageThread[0])}
                    </div>
                    <div className="p-2"><b>{messageThread[0].sent_from == businessPhoneNumber ? messageThread[0].to : messageThread[0].sent_from}</b> </div> {/*name*/}
                    <div className="p-2"> {recipientName["full_name"]} </div>
                    <div className="p-2"><p>{moment(messageThread[messageThread.length-1].sent).calendar()}</p></div> {/*time/date*/}
                </div>
                <div className="d-flex justify-content-start">
                    <div className="p-2">{messageThread[messageThread.length-1].content}</div> {/*content*/}
                </div>
                <hr></hr>
            </div>
        )
    }
}

export default ConversationPreview;