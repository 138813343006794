import React, { Component } from "react";
import SearchField from "../shared/SearchField";

class TableTop extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="rounded-block-header table-top">
                { this._newLink() }
                <SearchField action="/groups" search="name_cont" />
            </div>
        );
    }

    _newLink() {
        const { permissions } = this.props;

        if( permissions["create"] ) {
            return(<a href="groups/new" title="New Group."><i className="fas fa-plus" /></a>);
        } else {
            return null;
        }
    }
}

export default TableTop;
