import React, {Component} from "react";

//Mobile Line Item Header

class LineItemHeader extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return(
            <React.Fragment>
                <tr className="heading line-item-mobile">
                    <td style={{paddingLeft: "10px", margin: "auto"}} >
                        Items
                    </td>
                    <td/>
                    <td style={{textAlign: 'right', padding: "5px"}}>
                            {this.props.editable &&
                                <a id="add-line-item" onClick={this.props.addLineItem}> <i className="fas fa-plus fa-lg"></i> </a>
                            }
                    </td>
                </tr>
          </React.Fragment>
        );
    }
}

export default LineItemHeader;