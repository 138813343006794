import React, { Component } from 'react';
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";

class Table extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { tableData, permissions } = this.props;

        return (
            <div className="rounded-block">
                <table className="table mb-0 thead-border-top-0">
                    <TableHeader userWantsToMerge={this.props.userWantsToMerge}/>
                    <TableBody customersToMerge={this.props.customersToMerge} userWantsToMerge={this.props.userWantsToMerge} setUserWantsToMerge={this.props.setUserWantsToMerge} tableData={ tableData } permissions={ permissions } />
                </table>
            </div>
        );
    }
}

export default Table;
