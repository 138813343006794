import React, { Component, useState } from "react";
import LegalDisclaimer from "./LegalDisclaimer";
import TotalDue from "./TotalDue";
import LineItems from "./LineItems/LineItems";
import Header from "./Header/Header";
import Information from "./Information/Information";
import { Button } from "bootstrap";
import { success } from "toastr";

class InvoiceTemplateDocument extends Component {
    constructor(props) {
        super(props);
        let oldPriceSum = 0;

        const { invoice, lineItems, handlingInvoiceTemplate, logo, customerToInvoice, alternateLogoFromTemplate, business } = props;
        const { amount_paid } = invoice;

        lineItems.map((value)=>{ oldPriceSum += parseFloat(value.price) * parseInt(value.quantity); })

        const oldTotalAmount = parseFloat(oldPriceSum).toFixed(2) ? parseFloat(oldPriceSum).toFixed(2) : 0.00;

        const amountDue = parseFloat(oldTotalAmount).toFixed(2) || 0.00;
        const totalAmount = parseFloat(oldTotalAmount) || 0.00;
        const amountPaid = amount_paid || 0;
        const businessName = invoice.business_name || business.name;

        this.state = {
          invoiceTemplateId: invoice.id,
          customerToInvoice: customerToInvoice,
          notes: invoice.description || "",
          lineItems: lineItems || [],
          amountDue: amountDue,
          amountPaid: amountPaid,
          totalPrice: totalAmount,
          issuedDate: invoice.issued_on,
          logo: logo,
          alternateLogoFromTemplate: alternateLogoFromTemplate,
          alternativeBusinessName: businessName,
          handlingInvoiceTemplate: true,
          invoiceTemplateDisplayNumber: '0',
          invoiceType: 'Template',
          file: {}
        }

        this.updateLineItems = this.updateLineItems.bind(this);
        this.saveInvoiceTemplateToServer = this.saveInvoiceTemplateToServer.bind(this);
        this.setNotes = this.setNotes.bind(this);
        this.setLogo = this.setLogo.bind(this);
        this.removeLogo = this.removeLogo.bind(this);
        this.updateAlternateBusinessName = this.updateAlternateBusinessName.bind(this);
    }

    saveInvoiceTemplateToServer(){
        const formData = new FormData();
        const basePath = 'invoice_templates';
        const { file, alternativeBusinessName, invoiceTemplateId, notes, lineItems } = this.state;
        const { updateDisableSave, templateName, invoice } = this.props;
        const url = `/${basePath}/${ invoiceTemplateId ? invoiceTemplateId : ''}`;

        //url will be POST /invoices or POST /invoice_templates or PUT "/invoices/:id" or PUT "/invoice_templates/:id"
        //if invoiceTemplateId == null, do a POST to create new invoice, if invoiceTemplateId == not null, do a PUT to update the invoice.
        const requestType = `${invoiceTemplateId ? "PUT" : "POST"}`;
        const name = invoice.id ? templateName || invoice.name : templateName

        if(file) {
            formData.append('logo', file)
        }

        // if(!this.props.templateName || !notes) {
        //   updateDisableSave(false);
        //   return gritterError(`Error`, notes ? "Notes can't be blank" : "Template name can't be blank" )
        // }

        const invoiceTemplate = {
            'name': name,
            'id': invoiceTemplateId,
            'description': notes,
            'line_items': lineItems,
            'business_name': alternativeBusinessName
        }

        formData.append('invoice_template', JSON.stringify(invoiceTemplate));
        
        $.ajax({
            url: url,
            type: requestType,
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            success: function(data){
                console.log("Successfully saved invoice!");
                gritterSuccess("Success", "Successfully saved invoice!");
                window.location.href = `/${basePath}/${data.id}`;
            },
            error: function(data){
                console.log("Error Ocurred");
                updateDisableSave(false);
                gritterError(`Error`, "Something went wrong");
            }
        });
    }

    render() {
        return (
            <div className="invoice-box">
              <table cellPadding={0} cellSpacing={0} id="invoice-table">
                <tbody>
                  <Header
                    {...this.props}
                    defaultLogo={this.state.logo}
                    alternateLogoFromTemplate={this.state.alternateLogoFromTemplate}
                    onLogoChange={this.setLogo}
                    removeLogo={this.removeLogo}
                    invoiceType={this.state.invoiceType}
                    invoiceDisplayNumber = {this.state.invoiceTemplateDisplayNumber}/>

                  <Information {...this.props}
                    // alternativeBusinessName = {this.alternativeBusinessName}
                    alternativeBusinessName={this.state.alternativeBusinessName}
                    updateAlternateBusinessName={this.updateAlternateBusinessName}
                    notes={this.state.notes} 
                    setNotes = {this.setNotes}
                    invoiceType={this.state.invoiceType}
                    customerToInvoice={this.state.customerToInvoice}
                    serviceTo={this.state.serviceTo}/>

                  <LineItems
                    lineItems={this.state.lineItems}
                    updateLineItems={this.updateLineItems}
                    editable={this.props.editable}
                    productOptions = {this.props.productOptions}
                  />
                </tbody>
              </table>
              <TotalDue
                totalAmount={this.state.totalPrice}
                amountDue={this.state.amountDue}
                totalAmountPaid={this.state.amountPaid}
              />
              <hr />
              <LegalDisclaimer
                invoiceTerms = {this.props.business.invoice_terms}
                estimateTerms = {this.props.business.estimate_terms}
                invoiceType={this.state.invoiceType}
              />
          </div>
        );
    }


    setNotes(event, exactValue = null){
      this.setState({
        ...this.state,
        notes: exactValue || event.target.innerText
      })
    }

    updateLineItems(items, notes = null) {
      const priceSum = items.reduce(
        (previousValue, lineItem) => previousValue + (parseFloat(lineItem.price ? lineItem.price : 0.00) * lineItem.quantity), 0.00
      )

      const newTotalAmount = parseFloat(priceSum).toFixed(2) ? parseFloat(priceSum).toFixed(2) : 0.00;
      // const newDueAmount = newTotalAmount - parseFloat(this.props.invoice.amount_paid).toFixed(2);

      this.setState({
        ...this.state,
        lineItems: [...items],
        totalPrice: newTotalAmount,
        amountDue: newTotalAmount,
        notes: notes || this.state.notes,
      });
    }

    updateNotesAndLineItemsFromTemplateSelect(selection) {
      this.updateLineItems([...this.state.lineItems, 
        ...selection.line_items.map( 
          function(item) {
            return {
              id: item.id, 
              name: item.name, 
              price: item.price, 
              product_id: item.product_id, 
              quantity: item.quantity || 1} //this is to ensure there's no null quantity
          })
      ],
      selection.notes);//Hacky to tack on this notes to the updateLineItems call.
      //TODO: Add invoiceTemplateId to save onto this if it was seleted from template select.
    }        

    setLogo(event) {
      console.log('Image Changed');
      const src = event.target.value;

      if(src) {
          const file = event.target.files[0];
          const path = window.URL.createObjectURL(event.target.files[0]);

          this.setState({
            ...this.state,
            alternateLogoFromTemplate: path,
            file: file
          });
      }
    }

    removeLogo() {
      const { alternateLogoFromTemplate, invoiceTemplateId } = this.state;

      if(alternateLogoFromTemplate) {
        console.log('Remove logo');

        this.setState({
          ...this.state,
          alternateLogoFromTemplate: '',
          file: {}
        });

        $('#logoUpload').val('');

        if(invoiceTemplateId) {
          $.ajax({
            'url': `/invoice_template/${invoiceTemplateId}/logo`,
            'method': 'DELETE',
            'success': function(){
              gritterSuccess('Success', 'Logo removed successfully');
            },
            'error': function(error){
              console.log(error)
              gritterError('Error', 'Unable to delete logo');
            }
          })
        }
      }
    }

    updateAlternateBusinessName(event) {
        const { business } = this.props;
        const newBusinessName = event.target.innerText || business.name;
        console.log(newBusinessName)
        
        this.setState({
            ...this.state,
            alternativeBusinessName: newBusinessName
        })
    }
}

export default InvoiceTemplateDocument;