import React, {Component} from "react";

class ServiceTo extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const { handlingInvoiceTemplate, serviceTo } = this.props;
        const { full_name: serviceToFullName, email: serviceToEmail, phone: serviceToPhone, default_location_display: serviceToDefaultLocationDisplay, default_location: serviceTodisplayAddress} = serviceTo;

        return(
            <React.Fragment>
                <div
                    align="right"
                    // style={{display: `${this.props.shouldInvoiceOtherCustomer ? 'none' : 'block'}`}}
                    >
                    <b className="text-label"> Service To: </b> <br/>
                    <span> {serviceToFullName } </span>
                    <br/>
                    <span>
                        {
                            handlingInvoiceTemplate
                                ? serviceToDefaultLocationDisplay
                                : !isBlank(serviceTodisplayAddress.display_address) && serviceTodisplayAddress.display_address
                        }
                    </span> <br/>
                    <span> { serviceToEmail } </span> <br/>
                    <span> { formatPhoneNumber(serviceToPhone) } </span>
                </div>
                <br/>
            </React.Fragment>      
        );
    }
}

export default ServiceTo;