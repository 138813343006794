import React, { Component } from "react";
import JobCard from "./JobCard";

class JobIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data } = this.props;

        const responsiveStyleWrapper = {
            overflowX: "auto"
        }        

        return (
            <React.Fragment>
                {
                    data.map((jobData, index) => (
                        <JobCard jobData={jobData}></JobCard>        
                    ))
                }
            </React.Fragment>
        );
    }
}

export default JobIndex;
