import React, { Component } from "react";

class TableHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <thead>
                <tr>
                    <th>
                        <a href="javascript:void" data-toggle="modal" data-target="#new_job_appointment_modal">
                            <i className="fas fa-plus"></i>
                        </a>
                    </th>
                    <th>Employee</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                </tr>
            </thead>
        );
    }
}

export default TableHeader;
