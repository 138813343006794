import React, {Component} from "react";
class EventsIndex extends Component{
    constructor(props){
        super(props);
        this.state = {
            events: props.events,
        }
        this.fetchFilteredEvents = this.fetchFilteredEvents.bind(this);
    }

    fetchFilteredEvents = () => {
        let selectedBusinessId = $('#select_business').val()
        let url = isBlank(selectedBusinessId) ? '/events.json' : `/events.json?business_id=${selectedBusinessId}`;
        $.get({
            url: url,
            params: {business_id: 1},
            success: (data) => {
                this.setState({
                    ...this.state,
                    events: data
                });
            }
        })
    }

    render(){
        const { events, businesses, employees } = this.props;

        let businessOptions = businesses.map((business) => 
            <option key={`business-${business.id}`}  value={business.id}> {business.name} </option>
        );

        let employeeOptions = employees.map((employee) => 
            <option key={`employee-${employee.id}`} value={employee.id}> {employee.full_name}</option> 
        );

        let eventRows = this.state.events.reverse().map((event) => 
        // let eventRows = events.reverse().map((event) =>
            <tr className="b" key={`event-${event.id}`}>
                <td> {event.business_name} </td>
                <td> {event.employee_name} </td>
                <td>{ event.name}</td>
                <td>{ event.resource}</td>
                <td>{ event.resource_id}</td>
                <td>{ evaluateReadableDateTime(event.created_at)}</td>
            </tr>
        );
        return(
            <React.Fragment>
            <div className="d-flex justify-content-center">
                <div className="p-2">
                    {/* <label for="marketing_sources_chart_start" className="text-label invoice_type_due_label">Start Range</label> */}
                    <input className="flatpickr flatpickr-input" type="text" id="marketing_sources_chart_start"/>
                </div>
                <div className="p-2">
                    {/* <label for="marketing_sources_chart_end" className="text-label invoice_type_due_label">End Range</label> */}
                    <input className="flatpickr flatpickr-input" type="text" id="marketing_sources_chart_end"/>
                </div>
                <div className="p-2">
                    <button id="generate_event_report" className="btn btn-primary" onClick={this.fetchFilteredEvents}>Generate</button>
                </div>
            </div>

            <div className="d-flex justify-content-center">
            <div className="p-2">
                {/* <label for="select_business" className="text-label">Business</label>   */}
                <select id="select_business" className="form-control-lg">
                <option value="">All</option>
                {businessOptions}
                </select>
            </div>
            <div className="p-2">
                {/* <label for="select_employee" className="text-label">Employee</label>   */}
                <select id="select_employee" className="form-control-lg"> 
                <option value="">All</option>
                {employeeOptions}
                </select>
            </div>  
            </div>

            <div className="d-flex justify-content-center">
                <div className="p-2">
                    <div id="total_events_report"></div>
                </div>
            </div>

            <table className="table mb-0 thead-border-top-0">
            <thead>
                <tr>
                <th>Business</th>
                <th>Employee Name</th>
                <th>Event Type</th>
                <th>Resource</th>
                <th>Resource ID</th>
                <th>Time of Event</th>
                <th colSpan="3"></th>
                </tr>
            </thead>

            <tbody className="list" id="staff02">
                {eventRows}
            </tbody>
            </table>
            <br>
            </br>
            </React.Fragment>
        );
    }
}

export default EventsIndex;