import React, {Component} from "react";
import ContentEditable from "react-contenteditable"
import Select from "react-select"
//Desktop Line Item 

class LineItem extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const {name, description, quantity, price, productId} = this.props;
        const productSelectWidth = isBlank(name) ? "initial" : `${(8*(name || "").length) + 50}`;
        return(
            <React.Fragment>
                <tr className="line-item line-item-desktop" id="line-item-"> 
                  <td style={{width: `${productSelectWidth}px`}}>
                      { this.props.editable
                        ? <Select options={this.props.productOptions}
                          onChange={this.props.onCategoryLineSelected}
                          value={{value: productId, label: name}}
                          id="lineItemCategory"
                          className="lineItemCategory"
                          />
                        :
                        <span> {name} </span>
                      }
                  </td>
                  <td className="line-item-description" id="line-item-description-" style={{paddingLeft: "10px"}}>
                      <ContentEditable  
                        html={description || ""}
                        data-attribute="description"
                        onKeyDown={(e) => {
                          if (e.which == 13) e.preventDefault()
                        }}
                        onBlur={this.props.onLineItemChange}
                        placeholder={this.props.editable ? "Enter Description Here.." : ""}
                        disabled={!this.props.editable}
                      />
                  </td>
                  <td style={{display: 'none'}}>
                  </td>
                  <td style={{textAlign: 'right', paddingRight: "10px"}} className="line-item-quantity">
                    <ContentEditable
                        html={quantity + ""}
                        data-attribute="quantity"
                        onKeyDown={onlyIntegersAllowed}
                        onBlur={this.props.onLineItemChange}
                        onFocus={this.props.onLineItemFocus}
                        disabled={!this.props.editable}
                      />
                  </td>
                  <td className="price line-item-price" style={{textAlign: 'right'}}>
                      <ContentEditable
                        html={formatCurrency(price) + ""}
                        data-attribute="price"
                        onKeyDown={onlyNumbersAllowed}
                        onBlur={this.props.onLineItemChange}
                        onFocus={this.props.onLineItemFocus}
                        disabled={!this.props.editable}
                      />
                  </td>
                  {this.props.editable &&
                    <td style={{textAlign:"right", paddingLeft:"10px"}}> <a onClick={this.props.onRemoveItem}> <i className='fas fa-trash-alt'> </i> </a> </td>
                  }
                </tr>
            </React.Fragment>
        );
    }
}

export default LineItem;

