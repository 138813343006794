import React, { Component } from "react";

//** TODO: Implement. */
class Pay extends Component {
    constructor(props) {
        super(props);
    }

    render() { 
        const { acceptCardPayment, payWithStripe, payWithIbx, amountWithoutTransactionFee, transactionFee, invoice,  } = this.props;

        return (
            <div/>
        );
    }
}
 
export default Pay;