import React, { Component } from 'react';

class SearchField extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { action, search } = this.props;

        return (
            <form className="form-group" action={ action } acceptCharset="UTF-8" method="get">
                <input name="utf8" type="hidden" value="✓" />
                <input className="form-control" placeholder="Search..." type="text"
                       name={ `q[${search}]` }/>
            </form>
        );
    }
}

export default SearchField;
