import React, {Component} from "react";

class InvoiceTemplateLogo extends Component {
    constructor(props){
        //Only needs the image src url for the logo, the invoice document type, the invoice number, (need to be able to set invoice number too), the date + due date.
        super(props);
    }

    render() {
        const { onLogoChange, removeLogo, alternateLogoFromTemplate } = this.props;
        return(
            <React.Fragment>
                {
                    <div className='logo-upload inside' onClick={()=>$('#logoOption').toggle()}>
                        <i className="fas fa-pencil inside" style={{fontSize: '24px', opacity: 0.5}}></i>
                    </div>
                }
                <ul className="logo-option inside" id="logoOption">
                    <li className='inside' onClick={()=>{ $('#logoOption').hide(); $('#logoUpload').click()} }>
                        <i className="fas fa-camera inside" style={{fontSize: '12px', opacity: 0.5, paddingRight: '4px'}}/>
                        Upload
                    </li>
                    <li className={`inside ${alternateLogoFromTemplate ? '' : 'restrict-remove'}`} onClick={()=>{ $('#logoOption').hide(); removeLogo();} }>
                        <i className="fas fa-trash inside" style={{fontSize: '12px', opacity: 0.5, paddingRight: '4px'}}/>
                        Remove
                    </li>
                </ul>
                <input type="file" id='logoUpload' style={{display: 'none'}} onChange={onLogoChange}/>
            </React.Fragment>
        );
    }
}

export default InvoiceTemplateLogo;

