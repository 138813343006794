import React, {Component} from "react";

class Signature extends Component{
    constructor(props){
        super(props)
    }

    render(){
        const { signatureUrl } = this.props;
        return (
            <div className="d-flex justify-content-start invoice-only-section"> 
                <div className="p-2">
                    <div style={{ fontSize: '11.25px'}} className="invoice-only-section">        
                        <p> Signature: </p>
                        <img src={signatureUrl} alt="No Signature Found"/>
                    </div>
                </div>
            </div>          
        );
    }
}

export default Signature;