import React, { Component } from "react";
import SearchField from "../shared/SearchField";

class JobsTableTop extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { customerID } = this.props;

        return (
            <div className="rounded-block-header table-top">
                <SearchField action={ `/customers/${customerID}` }
                             search="notes_or_street_address_or_city_or_state_cont" />
            </div>
        );
    }
}

export default JobsTableTop;
