import moment from "moment";
import React, {Component} from "react";

class IssuedDateEditable extends Component {
    constructor(props){
        //Only needs the image src url for the logo, the invoice document type, the invoice number, (need to be able to set invoice number too), the date + due date.
        super(props);
        this.invoiceDueText = this.invoiceDueText.bind(this);
        this.formatHumanReadableDate = this.formatHumanReadableDate.bind(this);
    }

    invoiceDueText(invoiceType){
        if (invoiceType == "Invoice")
            return "Due"
        else if  (invoiceType == "Estimate")
            return "Expires"
        else 
            return ""
    }

    formatHumanReadableDate(dateStr){
        return moment(dateStr).format("MMMM Do, YYYY");
    }

    render() {
        return(
            <React.Fragment>
                <div>
                    <p style={{display: 'inline-block'}}> 
                        <span className=" text-label">
                            { this.props.handlingInvoiceTemplate ? 'Template ' : this.props.invoiceType + " "}
                        </span>
                        <span className="text-label"> 
                            Number:
                        </span>
                        <span style={{display: 'inline-block'}} className="p-2">
                            { this.props.invoiceDisplayNumber }
                        </span>
                    </p>
                </div>
                <label htmlFor="invoice-date-readonly2" className="text-label"> Date: </label>
                <span id="invoice-date-readonly2">
                    {/* <%= @invoice.issued_on.strftime("%B %d, %Y")%> */}
                    {" " + this.formatHumanReadableDate(this.props.invoice.issued_on)}
                </span>
                <br/>                                    
                {
                    !this.props.handlingInvoiceTemplate && <span id="invoice-due-date-section">
                        <label htmlFor="invoice-due-datereadonly2" className="text-label">
                            {this.invoiceDueText(this.props.invoiceType)+": "}
                        </label>
                        <span id="invoice-due-datereadonly2"> 
                            {this.formatHumanReadableDate(this.props.invoice.due_on)}
                        </span>
                    </span>
                }
            </React.Fragment>
        );
    }
}

export default IssuedDateEditable;

