import React, {Component} from "react";

class InvoiceNumberReadOnly extends Component {
    constructor(props){
        //Only needs the image src url for the logo, the invoice document type, the invoice number, (need to be able to set invoice number too), the date + due date.
        super(props);
    }

    //TODO: how to handle mobile..??
    render() {
        return(
            <React.Fragment>
                <div>
                    <label htmlFor="invoice-date-readonly" className="text-label"> Date: </label>
                    <span id="invoice-date-readonly">
                        { this.props.issuedDate }
                    </span>
                    <br />
                    <span id="invoice-due-date-section">
                        <label htmlFor="invoice-due-datereadonly" className="text-label invoice_type_due_label">{this.props.dueDateLabel}</label>
                        <span id="invoice-due-datereadonly"> 
                        { this.props.dueDate }
                        </span>
                    </span>
                </div>                         
            </React.Fragment>
        );
    }
}

export default InvoiceNumberReadOnly;

