import React, { Component } from 'react';

class TableBody extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { tableData } = this.props;
        return (
            <tbody>
            {
                tableData.map((data, index) => (
                    <tr key={index}>
                        <td>
                            <div className="avatar avatar-online">
                                <img src={data.avatar_path} className="avatar-img rounded-circle"></img>
                            </div>
                        </td>
                        <td>{ data.first_name }</td>
                        <td>{ data.last_name }</td>
                        <td>{ data.email }</td>
                        <td>  <i style={{color: data.color}} className="fas fa-brush fa-2x"></i></td> 
                        <td>{ data.name }</td>
                        { this._tools(data) }
                    </tr>
                ))
            }
            </tbody>
        );
    }

    _tools(data) {
        const { permissions } = this.props;

        if( permissions["destroy"] && permissions["update"] ) {
            return(
                <td>
                    {
                        data.deactivated_at
                            ? <a href={ `/restore_user/${data.id}`}
                                 data-method="put"
                                 data-confirm="Are you sure you want to restore this employee?"
                                 title="Restore">
                                <i className="fas fa-refresh" />
                            </a>
                            : <a href={ `/employees/${data.id}` }
                                 data-method="delete"
                                 data-confirm="Are you sure you want to delete this employee?"
                                 title="Delete">
                                <i className="fas fa-trash" />
                            </a>
                    }
                    <a href={ `/employees/${data.id}/edit` } title="Edit" className="px-2" >
                        <i className="fas fa-pencil-alt" />
                    </a>
                </td>
            );
        } else if( permissions["destroy"] ) {
            return(
                <td>
                    <a href={ `/employees/${data.id}` }
                       data-method="delete"
                       data-confirm="Are you sure you want to delete this employee?"
                       title="Delete">
                        <i className="fas fa-trash" />
                    </a>
                </td>
            );
        } else if( permissions["update"] ) {
            return(
                <td>
                    <a href={ `/employees/${data.id}/edit` } title="Edit">
                        <i className="fas fa-pencil-alt" />
                    </a>
                </td>
            );
        } else {
            return(<td />);
        }
    }
}

export default TableBody;
