import React, { Component } from "react";

class CSVModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="csv-modal" className="modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="col-12">Import Customers</h2>
                    </div>
                    <form id="form"
                          encType="multipart/form-data"
                          action="customers/import"
                          acceptCharset="UTF-8"
                          method="post"
                          style={{'width': '100%'}}>
                        <div className="modal-body form-group ">
                            <p>
                                Welcome to the Lightning Service Customer Import!
                            </p>
                            <p>
                                To Import customers into your account, please place your customers into a .csv file.
                                Currently this feature requires specific columns, if you do not have these data columns,
                                don’t worry! You can insert empty columns. Please ensure your csv does not contain
                                columns that are not apart of this list: <b>first_name</b>, <b>last_name</b>,
                                <b>city</b>, <b>phone</b>, <b>company</b>, <b>notes</b>, <b>state</b>,
                                <b>street_address</b>, <b>zip_code</b>, and <b>email</b>. If you’re missing a column,
                                you may leave itempty but please have a header for it at the top of the file.
                            </p>                            

                            <input name="utf8" type="hidden" value="&#x2713;"/>
                            <input type="hidden" name="authenticity_token"
                                   value="QbyVS86mco2Q4428vut7icp7rgjYaBPOdhrh6n/CjNF+2hJLemJO7vlOcWB61BKiJYhFMJUsbYsDLVD/RXz57g=="/>
                            <label htmlFor="csv-form-field">CSV file</label>
                            <input id="csv-form-field" type="file" name="file"/>
                            <span id="csv-dummy-field" className="form-control form-file-field"></span>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-yak cancel-modal">
                                Cancel
                            </button>
                            <input type="submit"
                                   className="btn btn-yak"
                                   name="commit"
                                   value="Import"
                                   data-disable-with="Import Customers"/>
                        </div>
                    </form>
                </div>

            </div>
        );
    }
}

export default CSVModal;
