import React, { Component } from 'react';

class TableBody extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { tableData } = this.props;

        return (
            <tbody>
            {
                tableData.map((data, index) => (
                    <tr>
                        <td>{ data.resource }</td>
                        <td>{ data.action }</td>
                        { this._toolsColumnField(data) }
                    </tr>
                ))
            }
            </tbody>
        );
    }

    _toolsColumnField(data) {
        const { displayTools } = this.props;

        if( displayTools ) {
            return(
                <td>
                    <a href={ `/groups/${data.group_id}/permissions/${data.id}` }
                       data-method="delete"
                       data-confirm="Are you sure?"
                       title="Delete">
                        <i className="fas fa-trash" />
                    </a>
                    <a href={ `/groups/${data.group_id}/permissions/${data.id}/edit` } title="Edit">
                        <i className="fas fa-pencil-alt" />
                    </a>
                </td>
            );
        } else {
            return null;
        }
    }
}

export default TableBody;
