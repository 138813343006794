import React, {Component} from "react";

class CustomerSideBySide extends Component{
    constructor(props){
        super(props);
    }

    formattedRecord(){
        Object.entries(this.props.customerRecord).forEach(([key, value]) => {
            console.log("Key: " + key);
            console.log("Value: "  + value);
        })
    }

    render(){
        const {customerRecord} = this.props;
        return (
            <div>
                {Object.entries(customerRecord).map(([key, value]) => {
                    console.log("Key: " + key);
                    console.log("Value: " + value);
                    return (
                        <div>
                            <div>
                                {key}: {value != null && value.constructor === "string".constructor ? value : JSON.stringify(value, null, 2)}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default CustomerSideBySide;