import CSVModal from "./CSVModal";
import React, { Component } from "react";
import Table from "./Table";
import TableTop from "./TableTop";

class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userWantsToMerge: false,
            customersToMerge: new Set(),
        }

        this.setUserWantsToMerge = this.setUserWantsToMerge.bind(this);
        this.mergeAllRecords = this.mergeAllRecords.bind(this);
    }

    setUserWantsToMerge(doesUserWantToMerge){
        console.log("Set user wants to merge was set to: " + doesUserWantToMerge)
        this.setState({...this.state, userWantsToMerge: doesUserWantToMerge})
    }

    mergeAllRecords(){
        let baseRecord = [...this.state.customersToMerge][0]; // we are taking first one checked to be base record
        this.state.customersToMerge.delete(baseRecord);

        $.post({
            url: "/customers_merge_all",
            data: {base_id: baseRecord, others_to_merge: [...this.state.customersToMerge]},
            success: function(data){
                gritterSuccess("Success", "Merged all customer records into one");
                window.location.reload();
            }
        })
    }    

    render() {
        const { tableData, permissions, connectedToQuickbooks } = this.props;
        console.log(connectedToQuickbooks)
        return (
            <React.Fragment>
                <div className="table-responsive border-bottom" data-toggle="lists" data-lists-values="['js-lists-values-employee-name']">
                    <TableTop setUserWantsToMerge = {this.setUserWantsToMerge} permissions={ permissions } connectedToQuickbooks = {connectedToQuickbooks} />
                    <Table customersToMerge={this.state.customersToMerge} setUserWantsToMerge = {this.setUserWantsToMerge} userWantsToMerge = {this.state.userWantsToMerge} tableData={ tableData } permissions={ permissions } />
                    {this.state.userWantsToMerge 
                        ? 
                            <div className="d-flex justify-content-center" style={{border: "1px solid", borderRadius: "16px"}}>
                                <div className="p-2">
                                    Merge {this.state.customersToMerge.size} customer records into one?
                                </div>
                                <div className="p-2">
                                    <button className="btn btn-primary" onClick={this.mergeAllRecords}> Yes </button> 
                                </div>
                                <div className="p-2">
                                    <button className="btn btn-cancel"> No </button> 
                                </div>
                            </div>
                        : 
                            null
                        }                    
                </div>
                <CSVModal />
            </React.Fragment>
        );
    }
}

export default Index;
