import React, { Component } from 'react';

class TableBody extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { tableData } = this.props;

        return (
            <tbody>
            {
                tableData.map((data, index) => (
                    <tr key={index}>
                    <td> <a href={`/invoices/${data.id}`}>{ data.title } </a> </td>
                        <td>{ formatCurrency(data.total_price) }</td>
                        <td>{ data.issued_on }</td>
                        <td>{ data.delivered_on }, {this._markAsDelivered(data.delivered_on != "Not Delivered", data.id) }</td>   
                        <td> { data.paid_in_full } { this._canMarkAsPaid(data.paid_in_full == "No", data.id) } </td>
                        { this._tools(data) }
                    </tr>
                ))
            }
            </tbody>
        );
    }   
    
    _tools(data) {
        const { tableData } = this.props;        
            return(
                <td>
                    <a href={ `/invoices/${data.id}.pdf` }
                        title="Download PDF">
                        <i className="fas fa-download fa-2x" aria-hidden="true"></i>
                    </a>
                    <a href={ `/invoices/${data.id}/edit` } title="Edit" style={{marginLeft:"5px"}}>
                        <i className="fas fa-pencil-alt fa-2x" />
                    </a>
                    <a href={ `/invoices/${data.id}` }
                        data-method="delete"
                        data-confirm="Are you sure?"
                        title="Delete">
                        <i className="fas fa-trash fa-2x" style={{color: "red", marginLeft: "5px"}}/>
                    </a>                                       
                </td>
            );
    }

    _canMarkAsPaid(wasntPaid, id){
        if (wasntPaid){
            return(
                <a title="Mark as paid" href="javascript:void(0)" onClick={() => this._markInvoiceAsPaid(id, this)}>
                    <i className="fas fa-money-bill-alt"></i>
                </a>
            );
        }
    }

    _markAsDelivered(wasDelivered, id){
        if (!wasDelivered){
            return(
                <a title="Email to Customer" href="javascript:void(0)" onClick={() => this._emailInvoiceToCustomer(id, this)}>
                    <i className="fas fa-paper-plane"></i>
                </a>
            );
        }
        else {
            return <a title="Email to Customer Again" href="javascript:void(0)" onClick={() => this._emailInvoiceToCustomer(id,this)}>
                <i className="fas fa-spinner"></i>
            </a>
        }
    }

    _emailInvoiceToCustomer(id){
        emailInvoiceToCustomer(id);
        window.location.reload();
    }

    _markInvoiceAsPaid(id, toReRender){        
        $.post({
            url: `../../invoices/${id}/mark_as_paid`,
            data: {id: id},
            success: function(){
                gritterSuccess("Successfully Marked as Paid", 
                    `You've successfully marked invoice: ${id} as paid in full`);
                window.location.reload();        
            },
            error: function(data){
                gritterError("Couldn't mark invoice as paid", "", "10000");
            }
        })
    }
}

export default TableBody;