import React, {Component} from "react";

import LineItem from "./LineItem";
import LineItemHeader from "./LineItemHeader"

class LineItems extends Component {
    constructor(props){
        super(props);
        this.addLineItem = this.addLineItem.bind(this);
    }

    addLineItem() {
        const { updateLineItems, lineItems } = this.props;
        const newLineItem = {
            name: null,
            product_id: null,
            description: null,
            quantity: 1,
            price: 0,
        }
        updateLineItems([...lineItems, newLineItem])
    }    

    render() {
        const { lineItems, editable, updateLineItems } = this.props;
        return(
            <React.Fragment>
                <LineItemHeader editable={editable} updateLineItems={updateLineItems} addLineItem={this.addLineItem}/>
                {
                    lineItems.map(
                        (lineItem, index) =>
                        <LineItem
                            key={`line_item_${index}`}
                            index={index}
                            editable={ editable }
                            lineItems={lineItems}
                            productId={lineItem.product_id}
                            updateLineItems={updateLineItems}
                            name={lineItem.name}
                            description={lineItem.description}
                            quantity={lineItem.quantity}
                            price={lineItem.price}
                            editLineItem={this.editLineItem}
                            productOptions={this.props.productOptions}
                        />
                    )
                }
            </React.Fragment>
        );
    }
}

export default LineItems;

