import React, {Component} from "react";
import ServiceTo from './ServiceTo';
import AddressModal from '../../../shared/AddressModal';
import AddressGenericModal from "../../../shared/AddressGenericModal";

class CustomerInformation extends Component {
    constructor(props){
        super(props);
        const { job, customerLocations } = props
        this.state = {
            addressModalShow: false,
            showGenericModal: false,
            customerLocations: customerLocations || [],
            job: job
        }

        this.openAddressModal = this.openAddressModal.bind(this);
        this.closeLocationModal = this.closeLocationModal.bind(this);
        this.openAddressGenericModal = this.openAddressGenericModal.bind(this);
        this.makeServiceAddress = this.makeServiceAddress.bind(this);
        this.updateAddressForm = this.updateAddressForm.bind(this);
    }

    openAddressModal(event){
        this.setState({
            ...this.state,
            addressModalShow: true
        })
    }

    closeLocationModal(event){
        this.setState({
            ...this.state,
            addressModalShow: false,
        })
    }

    openAddressGenericModal(showAddressModal) {
        this.setState({ ...this.state, showGenericModal: showAddressModal })
    }

    makeServiceAddress(newServiceAddress){
        $(".address-menu").css('display','none');

        const { id } = newServiceAddress;
        const { id:job_id } = this.props.job;
        const that = this;

        $.ajax({
            method: 'PUT', 
            url: `/jobs/${job_id}/update_attribute`,
            data: {attribute: 'location_id', value: id},
            success: function(data){
                that.setState({
                    ...that.state,
                    job: data
                })
                gritterSuccess("Success", "Successfully Set Service Address");
            },
            error: function(data){
                gritterError("Error", "Something Error", "10000");
            }
        });
    }

    updateAddressForm(){
        const that = this;
        const { streetAddress, city, state, zipCode, id:newLocationId } = this.genericAddressModal?.state?.addressFormData;
        const locationMethod = newLocationId != '' ? 'PUT' : 'POST';
        const locationUrl = newLocationId != '' ? "/locations/" + newLocationId + ".json" : "/locations.json";

        const location = {
            location: {
                street_address: streetAddress,
                city: city,
                zip_code: zipCode,
                state: state,
                customer_id: this.state?.job?.location?.customer_id
            }
        };

        $.ajax({
            method: locationMethod, 
            url: locationUrl,
            data: location,
            success: function(data){
                $.ajax({
                    method: 'GET',
                    url: `/jobs/${that.props.job.id}.json`,
                    success: function(data){
                        that.setState({
                            ...that.state,
                            job: data
                        })
                    },
                    error: function(data){
                        gritterError("Error", "Service Address Not Changed", "10000");
                    }
                });

                newLocationId != ''
                    ? that.setState({
                        ...that.state,
                        customerLocations: that.state.customerLocations.map(ldata => ldata.id == data.id ? data : ldata),
                    })
                    : that.setState({
                        ...that.state,
                        customerLocations: [ ...that.state.customerLocations, data ],
                    })
                
                if(that.state?.job?.location?.id == data?.id) {
                    const updatedJob = { ...that.state.job, location: data }
                    that.setState({ ...that.state, job: updatedJob, })
                }

                that.genericAddressModal.handleAddressFormShow(false);
                gritterSuccess("Success", "Address Saved Successfully");
            },
            error: function(data){
                gritterError("Error", "Something Error", "10000");
            }
        });
    }

    render() {
        const { invoiceType, handlingInvoiceTemplate, customerToInvoice, serviceTo, invoice, showServiceTo, editable } = this.props;
        const { id:customerId, full_name:fullName, default_location_display:defaultLocationDisplay, email:customerEmail, phone: customerPhone, default_location: customerDefaultLocation} = customerToInvoice;
        const { addressModalShow, job } = this.state;

        return(
            <td> 
                <br/>
                <div align="right">
                    <b className="text-label">
                        { editable && !isBlank(job) && !isBlank(job.location) && <a id="editInvoiceTo" href="javascript:void(0)" onClick={this.openAddressGenericModal} ><i className="fas fa-pencil-alt" aria-hidden="true"></i></a>}
                        <span className="invoice_type"> { invoiceType } </span> To:
                    </b> <br/>
                    <span>
                        { fullName }
                    </span>
                    <br/>
                    <span>
                        { !isBlank(defaultLocationDisplay?.replace(",","")) && defaultLocationDisplay }
                    </span> <br/>
                    {!isBlank(customerEmail) && <React.Fragment> <span> { customerEmail }</span> <br/> </React.Fragment>}
                    {!isBlank(customerPhone) && <React.Fragment> <span> { formatPhoneNumber(customerPhone) } </span> </React.Fragment>}
                </div>
                <br/>
                <br/>
                { 
                    (handlingInvoiceTemplate || ( customerId != this.props.serviceTo?.id)) && showServiceTo && serviceTo
                    && <ServiceTo
                        serviceTo={ handlingInvoiceTemplate ? customerToInvoice : serviceTo }
                        handlingInvoiceTemplate={handlingInvoiceTemplate}
                    />
                }
                {
                    !isBlank(job)
                    && !isBlank(job.location)
                    && <div align="right"
                        style={{display: `${(customerDefaultLocation?.id != job?.location?.id) || showServiceTo ? 'block' : 'none'}`}}>
                    <div className="text-label">{ editable && <a href="javascript:void(0)" onClick={() => {this.openAddressGenericModal(true)}} ><i className="fas fa-pencil-alt" aria-hidden="true"></i></a>} Service Address: </div>
                        { this.state?.job?.display_address }
                    </div>
                }
                <AddressModal
                    {...this.props}
                    addressModalShow={addressModalShow}
                    closeLocationModal={this.closeLocationModal}
                    toDifferentCustomerCheckbox={this.props.toDifferentCustomerCheckbox}
                    showingDiffCust={this.props.showingDiffCust}
                    onDifferentCustomerSelected={this.props.onDifferentCustomerSelected}
                    getCustomerSearchOptions={this.props.getCustomerSearchOptions}
                    selectedDiffCustOption={this.props.selectedDiffCustOption}
                />
                { 
                    this.state.showGenericModal
                    && <AddressGenericModal
                        ref={(ip) => {this.genericAddressModal = ip}}
                        modalTitle='Manage Properties'
                        openAddressGenericModal={this.openAddressGenericModal}
                        serviceAddress={this.state?.job?.location}
                        customerLocations={this.state?.customerLocations}
                        makeServiceAddress={this.makeServiceAddress}
                        updateAddressForm={this.updateAddressForm}
                    />
                }
            </td>            
        );
    }
}

export default CustomerInformation;