import React, {Component} from 'react';

class InplaceEdit extends Component{
    constructor(props){
        super(props);
        this.onFocusOut = this.onFocusOut.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
    }
    
    render(){
        const {attribute, resource, resource_id, value, allowNewline} = this.props;   
        let inplaceId = `inplace-${resource}-${attribute}-${resource_id}`;             
        this.val = value;

        return (
            <span className="inplace-edit" 
                id={inplaceId}
                data-attribute={attribute} 
                data-resource={resource}
                data-id={resource_id}
                data-value={value}
                contentEditable={true}
                onBlur={this.onFocusOut}
                placeholder={this.titleizeAttribute(attribute)}
                onKeyPress={allowNewline ? null : (e) => {e.key === 'Enter' && e.preventDefault();}}>
                {value}
            </span>            
        );
    }

    /**
     * Will send a 'PUT' to the update attribute url with the new data
     */
    onFocusOut(event){        
        const newVal = event.currentTarget.innerText;
        if (newVal == this.val){
            return
        }
        else {
            this.val = newVal;
        }
        
        $.ajax({
            type: 'PUT',
            url: this.updateUrl(),
            data: {attribute: this.props.attribute, value: event.currentTarget.innerText},
            success: () => {
                var successMessage = `Successfully set ${this.titleizeAttribute(this.props.attribute)} to: "${this.val}"`;
                if (this.val.length == 0){
                    successMessage = `Successfully cleared ${this.titleizeAttribute(this.props.attribute)}`;
                }
                console.log(successMessage);
                gritterSuccess("Successful Update", successMessage);
            },
            error: function(data){
                const failureMessage = `Failed to set: ${this.titleizeAttribute(this.props.attribute)}`;
                console.log(failureMessage);
                gritterError("Failed Update", failureMessage);
            },
        })
    }

    /**
     * Gets the update attribute URL based on the given resource and resource id.
     */
    updateUrl(){
        return `/${this.props.resource}/${this.props.resource_id}/update_attribute.json`;
    }
    
    /**
     * Takes a rails formatted attribute and returns the titleized verison of it. 
     * For example: "first_name" => "First Name", "notes" => "Notes"
     * @param {*} attr 
     */
    titleizeAttribute(attr){
        if (typeof attr != "string")
            return attr        
        return attr.split('_').map(function(val){return val.charAt(0).toUpperCase() + val.slice(1)}).join(' ');
    }    
}

export default InplaceEdit;