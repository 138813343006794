import React, { Component } from "react"; 
import PaidInvoices from "./PaidInvoices";
import OutstandingInvoices from "./OutstandingInvoices";
import LateInvoices from "./LateInvoices";


class InvoiceTracking extends Component {
    constructor(props) {
        super(props);   
    }

    render() {
        const { outstandingInvoices, lateInvoices, paidInvoices } = this.props;

        return (
        <div className="table-responsive"> 
            <table className="table">
                <thead>
                    <tr>
                        <th>Invoice Title</th>
                        <th>Amount Due</th>
                        <th>Due On</th>
                    </tr>
                </thead>
                <tbody>
                    <LateInvoices invoices = {lateInvoices} />
                    <OutstandingInvoices invoices = {outstandingInvoices} />
                    <PaidInvoices invoices = {paidInvoices} />
                </tbody> 
            </table>
        </div>);
    }
}

export default InvoiceTracking;