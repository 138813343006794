import React, { Component } from "react";

class SortLink extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { name, url, dbParamName } = this.props;
        const paramName = dbParamName || this._paramName();
        const direction = (this._checkURL(`${paramName}+asc`)) ? "desc" : "asc";

        return (
            <a className="sort_link" href={`${url}?q%5Bs%5D=${paramName}+${direction}`}>{ `${name} ${this._arrowIcon()}` }</a>
        );
    }

    /**
     * Returns an arrow icon if page is sorted on this field.
     * Returns a blank string otherwise.
     *
     * @private
     */
    _arrowIcon() {
        const { dbParamName } = this.props;
        const paramName = dbParamName || this._paramName();

        if( this._checkURL(`${paramName}+asc`) ) {
            return " ▲";
        }
        if( this._checkURL(`${paramName}+desc`) ) {
            return " ▼";
        }

        return "";
    }

    /**
     * Returns true if URL contains value, else returns false.
     * @private
     */
    _checkURL(value) {
        return window.location.href.toLowerCase().includes(value);
    }

    /**
     * Returns url parameter name for this link.
     *
     * @private
     */
    _paramName() {
        const { name } = this.props;

        return name.toLowerCase().replace(" ", "_");
    }
}

export default SortLink;
