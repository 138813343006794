import React, { Component } from "react";
import SortLink from "../shared/SortLink";

class TableHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <thead>
                <tr>
                    <th>Title</th>                    
                    <th><SortLink name="Total Price" url={this.props.sortingUrl}> </SortLink></th>
                    <th><SortLink name="Issued On" url={this.props.sortingUrl}> </SortLink></th>
                    <th><SortLink name="Delivered On" dbParamName="sent_on" url={this.props.sortingUrl}> </SortLink></th>
                    <th>Paid</th>
                    <th>Actions</th>
                </tr>
            </thead>
        );
    }
}

export default TableHeader;
