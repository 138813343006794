
import React, {Component} from "react";

class MessageBubbleSent extends Component{
    constructor(props){
        super(props);
    }

    render(){
        const {content, time, imageSrc} = this.props;

        if (!imageSrc && !content) {
            return null
        }

        const image = ( imageSrc == null || imageSrc.trim() === '' ? <div/> : 
            <div style={{width: '200px', height: '200px'}}>
                <a className="lightbox" href={imageSrc}>
                    <img className={`${imageSrc ? 'd-block' : 'd-none'} rounded w-50`}
                        src={imageSrc}
                        style={{width: '100%', height: '100%', objectFit: 'contain'}}
                    />
                </a>
            </div>
        )

        return (
            <div>
                <br></br>
                <div className="yours messages">
                    {image}
                    <div className={`${content ? 'd-block' : 'd-none'} hsc-message last`}>
                        {content}
                    </div>
                </div>
                <div className="message-info-yours">
                    {time}
                </div>
            </div>
        );
    }
}
export default MessageBubbleSent;