import React, { Component } from "react";
import Request from "./Request"

class RequestsDraggable extends Component {
    constructor(props){
        super(props);
    }

    componentDidMount(){ 
        let draggableContaineEl = document.getElementById('job_request_container');
        new FullCalendarInteraction.Draggable(draggableContaineEl, {
            itemSelector: '.draggable-el',
            eventData: function(eventEl) {
                return {
                    title: eventEl.innerText,
                    duration: '02:00',
                    extendedProps: { //extended props set's the event info fields for fullcalendar to use, pulling data from the data attribute tags
                        requestId: eventEl.dataset.requestid,
                        customerId: eventEl.dataset.customerid,
                        locationId: eventEl.dataset.locationid,
                        firstName: eventEl.dataset.firstname,
                        lastName: eventEl.dataset.lastname,
                        email: eventEl.dataset.email,
                        phone: eventEl.dataset.phone,
                        streetAddress: eventEl.dataset.streetaddress,
                        zip: eventEl.dataset.zip,
                        city: eventEl.dataset.city,
                        state: eventEl.dataset.state,
                        summary: eventEl.dataset.summary,
                        notes: eventEl.dataset.notes,
                        requestId: eventEl.dataset.requestid,
                        marketingSourceId: eventEl.dataset.marketingsourceid,
                        marketingSourceName: eventEl.dataset.marketingsourcename
                    }
                }
            }
        });
    }

    render(){
        const {requests} = this.props;
        return ( //todo: each draggable.
            <div className="card" id="job_request_container">
                <div className="card-header card-header-large bg-white">
                    <h4 className="card-header__title">Drag to Schedule</h4>
                </div>
                <div className="card-body">
                    <div className="form-row" data-toggle="dragula" id="draggable-container">
                        {
                            requests.map((request, index) => (
                                <Request request = {request} key={`request-${index}`} />
                            ))
                        }
                    </div>
                </div>
            </div>            
        )
    }

}

export default RequestsDraggable;