import React, {Component} from "react";
import BillToDifferentCustomer from "../invoices/BillToDifferentCustomer";

class AddressModal extends Component {
    constructor(props){
        super(props);
    }

    render() { 
        const { addressModalShow, modalTitle } = this.props

        return (
            <>
            {
                addressModalShow && <div className="modal" id="locationModal" style={{display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)"}} >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Manage Locations</h4>
                                <a href="javascript:void(0);" onClick={this.props.closeLocationModal} ><i className="fas fa-times"></i></a>
                            </div>

                            <div className="modal-body">
                                <div className="row" >
                                    <div className="col-lg-12" >
                                        <BillToDifferentCustomer
                                            {...this.props}
                                            toDifferentCustomerCheckbox={this.props.toDifferentCustomerCheckbox}
                                            showingDiffCust={this.props.showingDiffCust}
                                            onDifferentCustomerSelected={this.props.onDifferentCustomerSelected}
                                            getCustomerSearchOptions={this.props.getCustomerSearchOptions}
                                            selectedDiffCustOption={this.props.selectedDiffCustOption}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            }
            </>
        );
    }
}
 
export default AddressModal;