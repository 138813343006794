import React, {Component} from 'react';

class ClickableCard extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const { content, onClick } = this.props;

        return(
            <div className="container-fluid page__container clickable-card" onClick={onClick}>
                <div className="card card-form">
                    <div className="card-form__body card-body">
                        {content}
                    </div>
                </div>
            </div>
        );
    }
}

export default ClickableCard;