import React, {useState} from "react";

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Flatpickr from "react-flatpickr";

export default function TimesheetEditModal(props){
    const {activeTimesheetId,
         timesheetCustomerName,
         timesheetEmployeeName,
         clockedInTime,
         clockedOutTime,
         timesheets,
         setTimesheets,
         isOpen,
         setIsOpen} = props;

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)} centered>
        <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form>
                <label className="text-label invoice_type_due_label">Clocked In:</label>
                <Flatpickr
                    value={moment(clockedInTime).format("YYYY-MM-DDTHH:mm:ssZ")}
                    data-enable-time
                    options={flatpickrOptions}
                    onChange={([date]) => {
                        console.log("datetime changed: " + date)
                        setClockedInTime(date);
                    }}
                />

                <label className="text-label invoice_type_due_label">Clocked Out:</label>
                <Flatpickr
                    value={moment(clockedOutTime).format("YYYY-MM-DDTHH:mm:ssZ")}
                    data-enable-time
                    options={flatpickrOptions}
                    onChange={([date]) => {
                        console.log("datetime changed: " + date)
                        setClockedOutTime(date);
                    }}
                />
            </form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Close
        </Button>
        <Button variant="primary" onClick={saveTimesheetChangesToServer}>
            Save Changes
        </Button>
        </Modal.Footer>
    </Modal>
    )
}