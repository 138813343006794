import React, {Component} from "react";
import InvoiceCard from "./InvoiceCard";

class InvoiceIndex extends Component{
    constructor(props){
        super(props);
    }

    render(){
        const {invoices} = this.props;
        
        //TODO: We'll map over all the invoices and create an InvoiceCard for each one.
        
        return(
            <React.Fragment>
                {
                    invoices.map((invoice, index) => (
                        <InvoiceCard invoice={invoice} key={index}/>
                    ))
                }
            </React.Fragment>
        )
    }
}

export default InvoiceIndex;