import React, { Component } from "react";
class SendMessageToolbar extends Component{
    constructor(props) {
        super(props);

        this.changeHandler = this.changeHandler.bind(this);
        this.resetImage = this.resetImage.bind(this);
        this.handleSend = this.handleSend.bind(this);
        this.resetData = this.resetData.bind(this);
    }

    changeHandler(event) {
        let src = event.target.value;
        if(src) {
            $('#image_avatar_url_id').val('');
            const url = window.URL.createObjectURL(event.target.files[0])

            $('#attached-image').attr('src',url);
            $('#attached-image-container').show();
            $('#image_upload').hide();
        }
    }

    resetImage() {
        $('#image_avatar').val('')
        $('#image_avatar_url_id').val('');
        $("#attached-image-container").hide()
        $("#attached-image").attr("src", '');
        this.setState({ imageSrc: '', file: {}})
    }

    resetData() {
        this.resetImage();
        $('#message_to_send').val('');
    }

    handleSend() {
        const imageSrc = $('#attached-image').attr('src')
        const file = $('#image_avatar')[0].files[0] || {}
        const uploadedImageId = $('#image_avatar_url_id').val();

        const messagePayload = {
            image: {
                file: file,
                imageUrl: imageSrc,
                id: uploadedImageId
            },
            message: $('#message_to_send').val()
        }

        const { addNewMessageCallback } = this.props;
        addNewMessageCallback(messagePayload, this.resetData); 
    }

    render(){

        return(
            <div className="p-2 flex-grow-1">
                <form onSubmit= {(e) => {
                        e.preventDefault();
                    }}>
                    <div className="d-flex">
                        <div className="p-2 flex-grow-1 form-control" style={{height: 'fit-content'}}>
                            <div id="attached-image-container" className="rounded w-25 overflow-hidden" style={{height: '200px', display: 'none'}}>
                                <button type="button" className="close d-flex justify-content-center align-items-center rounded-circle" aria-label="Close"
                                    style={{
                                        boxShadow: '#000 0px 5px 15px !important',
                                        height: '20px',
                                        width: '20px',
                                        transform: 'translate(50%, 50%) !important'
                                    }}
                                    onClick={() => this.resetImage()}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <img id="attached-image" className={'d-block rounded w-100 h-100'} src="" />
                            </div>
                            <textarea className="form-control border-0"
                                    rows="2"
                                    type="text" 
                                    id="message_to_send" 
                                    name="message_text"
                                    placeholder="Message" />
                        </div>
                        <input type="file"
                            id="image_avatar"
                            name="avatar"
                            accept="image/png, image/jpeg"
                            style={{display: 'none'}}
                            onChange={(event) => {
                                this.changeHandler(event)
                            }}
                        />
                        <input type="number"
                            id="image_avatar_url_id"
                            name="avatar"
                            style={{display: 'none'}}
                            onChange={(event) => {
                                this.urlChangeHandler(event)
                            }}
                        />
                        <div className="p-2 pt-3 ml-auto">
                            <a href="#image_upload" className="btn-company-link" title="Upload" id="upload_image_button" onClick={()=>$('#image_upload').show()}><i className="fa fa-paperclip fa-2x" aria-hidden="true"></i></a>
                        </div>
                        <div className="p-2 pt-3 ml-auto">
                            <button type="button"
                                className="btn-company-link"
                                onClick = {() => {
                                    this.handleSend()
                                }}
                                id="send_text_button">
                                    <i className="fa fa-arrow-circle-up fa-2x" aria-hidden="true"></i> 
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default SendMessageToolbar;