import React, {Component} from "react";
import ContentEditable from "react-contenteditable"

class IssuedDateReadOnly extends Component {
    constructor(props){
        //Only needs the image src url for the logo, the invoice document type, the invoice number, (need to be able to set invoice number too), the date + due date.
        super(props);

        this.onDueDateChange = this.onDueDateChange.bind(this);
        this.onIssueDateChange = this.onIssueDateChange.bind(this);
        this.invoiceDueText = this.invoiceDueText.bind(this);
    }
    
    componentDidMount() {
        flatpickr("#invoice-date", {
            altInput: true,
            altFormat: "F j, Y",
            disableMobile: "true",
            onChange: this.onIssueDateChange
        });
        
        flatpickr("#invoice-due-date", {
            altInput: true,
            altFormat: "F j, Y",
            disableMobile: "true",
            onChange: this.onDueDateChange
        });

        SetFlatpickr('invoice-date', this.props.issuedDate);

        SetFlatpickr('invoice-due-date', this.props.dueDate);
    }

    invoiceDueText(invoiceType){
        if (invoiceType == "Invoice")
            return "Due"
        else if  (invoiceType == "Estimate")
            return "Expires"
        else 
            return ""
    }    

    onIssueDateChange(selectedDates, date_str, instance) {
        const { setDates } = this.props;
        const currentInvoiceDueDate = $('#invoice-due-date').val();

        setDates(date_str, false)
        if(Number(new Date(date_str)) > new Date(currentInvoiceDueDate)) {
            setDates(date_str, true); //Updating due date when issue date is selected beyond due date
            SetFlatpickr('invoice-due-date', date_str);
        }
    }

    onDueDateChange(selectedDates, date_str, instance) {
        const { setDates } = this.props;
        const currentInvoiceIssuedDate = $('#invoice-date').val();

        setDates(date_str, true)
        if(Number(new Date(date_str)) < new Date(currentInvoiceIssuedDate)) {
            setDates(date_str, false); //Updating isseue date when due date is selected before issue date
            SetFlatpickr('invoice-date', date_str);
        }
    }

    render() {

        return(
            <React.Fragment>
                <div>
                    <span className="invoice_type text-label">
                        { this.props.handlingInvoiceTemplate ? 'Template ' : this.props.invoiceType + " "}
                    </span>
                    <span className="text-label"> 
                        Number:
                    </span>
                    <span style={{display: 'inline-block'}} className="p-2">
                        <ContentEditable
                            html={this.props.invoiceDisplayNumber}
                            onBlur={this.props.setInvoiceDisplayNumber}
                            disabled={!this.props.editable}
                        />
                    </span>                        
                </div>                
                <div> 
                    <div className="d-flex justify-content-end">
                        <div className="p-2">
                            <label htmlFor="invoice-date" className="text-label"> Date: </label>
                            <input className="flatpickr flatpickr-input" type="date" id="invoice-date"/>
                            <br/>
                        </div>
                    </div>
                    
                    <div className="d-flex justify-content-end">
                        {
                            <span id="invoice-due-date-section" style={{display: this.props.showDueDate ? "block" : "none"}}>
                                <div className="p-2">                                        
                                    <label htmlFor="invoice-due-date" className="text-label invoice_type_due_label">{this.invoiceDueText(this.props.invoiceType)}:</label>
                                    <input className="flatpickr flatpickr-input" type="date" id="invoice-due-date"/>
                                </div>
                            </span>
                        }
                    </div>
                </div>         
            </React.Fragment>
        );
    }
}

export default IssuedDateReadOnly;

