import React, { Component } from "react";

class MarkInvoiceAsPaidIcon extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { invoiceId } = this.props;

        return (
            <a title="Mark as Paid" href="javascript:void(0)" className="dropdown-item" onClick={() => markAsPaid(invoiceId, this)}>
                <i className="fas fa-hand-holding-usd fa-2x"> Mark as Paid (Manual)</i>
            </a>
        );
    }
}

export default MarkInvoiceAsPaidIcon;
