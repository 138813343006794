import React, {Component} from "react";
import ContentEditable from "react-contenteditable"

class BusinessInformation extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const { business, businessPhone, handlingInvoiceTemplate, editable, updateAlternateBusinessName, alternativeBusinessName } = this.props;
        const { name: defaultBusinessName, email: businessEmail, street_address: businessSreetAddress, city: businessCity, state: businessState, zip_code: businessZipCode, notes: businessNotes } = business;
        const businessName = alternativeBusinessName || defaultBusinessName;

        return(
            <td>
                <div>
                    {/* <span style={{fontWeight: 'bold'}}> {this.props.alternativeBusinessName ? this.props.alternativeBusinessName : this.props.business.name} </span> <br /> */}
                    <br/>
                    <div style={{position: 'relative', width: 'fit-content'}}>
                        <span style={{fontWeight: 'bold'}} contentEditable={handlingInvoiceTemplate && editable} id="businessName" onBlur={updateAlternateBusinessName}>
                            {businessName}
                        </span>
                        {
                            handlingInvoiceTemplate && editable && <div className='editable-icon' onClick={()=>document.getElementById("businessName").focus()}>
                                <i className="fas fa-pencil" style={{fontSize: '1rem', opacity: 0.5}}></i>
                            </div>
                        }
                    </div>
                    <span> <i className="fas fa-phone" /> {businessPhone} </span> <br />
                    <span> <i className="fas fa-envelope" /> {businessEmail} </span> <br />
                    <span><i className="fas fa-map-marker-alt" /> { `${businessSreetAddress}, ${businessCity}, ${businessState} ${businessZipCode} `}</span> 
                    <br />
                    { !isBlank(businessNotes) && <span>{businessNotes}</span> }
                </div>
                <div>
                    <br />
                    {
                        !isBlank(this.props.invoice.owner) && <p>
                            <span className="text-label"> Customer Rep: </span> {this.props.invoice.owner.full_name} <br/>
                            <span className="text-label"> Phone: </span><i className="fas fa-phone"></i> { this.props.invoice.owner.phone} <br />
                            <span className="text-label"> Email: </span><i className="fas fa-envelope"></i> { this.props.invoice.owner.email }<br/><br/>
                        </p>
                    }
                    <br />
                </div>
            </td>
        );
    }
}

export default BusinessInformation;