import React, { Component } from 'react';

class TableBody extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { tableData } = this.props;

        return (
            <tbody>
            {
                tableData.map((data, index) => (
                    <tr key={index}>
                        <td></td>
                        <td>{ data.employee_name }</td>
                        <td>{ data.time_start }</td>
                        <td>{ data.time_end }</td>
                    </tr>
                ))
            }
            </tbody>
        );
    }
}

export default TableBody;
