import React, { Component } from "react";
import SortLink from "../shared/SortLink";

class TableHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <thead>
                <tr>
                    <th/>
                    <th><SortLink name="Name" url="/customers" /> </th>                     
                    <th>Phone</th>
                    <th><SortLink name="Email" url="/customers" /></th>  
                    <th></th>
                </tr>
            </thead>
        );
    }
}

export default TableHeader;
