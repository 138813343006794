import React, {Component} from "react"
import ConversationPreview from "./ConversationPreview"

class AllConversations extends Component{
    constructor(props){
        super(props);
    }

    render(){
        const {messageThreads, businessPhoneNumber, setMessageThreadCallback, pageNumber} = this.props;
        return(
            <div className="container" style= {{borderRight: '1px solid #cecece'}}> 
                <div className="row">
                    <div className="col">
                        {messageThreads.map((data, index) => { //data[1]gets all the messages, [0] gets the first one that we'll use to preview the convo.
                            return (<ConversationPreview
                                messageThread = {data[1]['messages']} //data[1]['messages'] => [<ActiveRecord::Messages]
                                setMessageThreadCallback = {setMessageThreadCallback}
                                recipient = {data[0]}//data[0] => '8013499925'
                                recipientName = {data[2]} //data[2] => 'John Doe'
                                hasUnreadMessage = {true}
                                businessPhoneNumber={businessPhoneNumber}
                                urls={data[1]["urls"]}
                                key = {`conversation-preview-${index}`}>
                            </ConversationPreview>);
                    })}
                    </div>
                </div>
                {pageNumber > 0 ? 
                <a href={`/messages?page=${pageNumber-1}`}> 
                    <i className='fa-solid fa-arrow-left'> </i>
                </a> : <span/>}
                <span> Page {pageNumber + 1} </span>
                <a href={`/messages?page=${pageNumber+1}`}> 
                    <i className='fa-solid fa-arrow-right'> </i>
                </a>
            </div>
        )
    }
}

export default AllConversations;