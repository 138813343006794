import React, { Component } from "react";

class TableTop extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="d-flex bd-highlight">
                { this._newLink() }
            </div>
        );
    }

    _newLink() {
        const { permissions } = this.props;

        if( permissions["create"] ) {
            return( <a href="rosters/new" title="New Roster."><i className="fas fa-plus" /></a> );
        } else {
            return null;
        }
    }
}

export default TableTop;
