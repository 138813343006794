import React, {Component} from "react";
import ContentEditable from "react-contenteditable"
import BusinessInformation from "./BusinessInformation";
import CustomerInformation from "./CustomerInformation";

class Information extends Component {
    constructor(props){
        super(props);
        this.formatHtmlForNotes = this.formatHtmlForNotes.bind(this);
    }

    formatHtmlForNotes(notes){
        let notesArray = notes.split('\n');
        let htmlString = ""
        
        for (let i = 0; i < notesArray.length; i++){
            let item = notesArray[i];
            if (i == notesArray.length-1){
                htmlString +=  item + "";
            }
            else {
                htmlString += `<span>${item}<br/></span>`
            }
        }
        return isBlank(htmlString) ? "" : htmlString;
    }

    render() {
        return(
            <React.Fragment>
                <tr className="information">
                    <td colSpan={this.props.editable ? 5 : 4}>
                        <table>
                            <tbody>
                                <tr>
                                    <BusinessInformation {...this.props}/>
                                    {
                                        this.props.customerToInvoice && <CustomerInformation
                                            {...this.props}
                                            toDifferentCustomerCheckbox={this.props.toDifferentCustomerCheckbox}
                                            showingDiffCust={this.props.showingDiffCust}
                                            onDifferentCustomerSelected={this.props.onDifferentCustomerSelected}
                                            getCustomerSearchOptions={this.props.getCustomerSearchOptions}
                                            selectedDiffCustOption={this.props.selectedDiffCustOption}
                                        />
                                    }
                                </tr>
                                <tr>
                                    <td colSpan={1000}>
                                        <div className="text-label"> Notes </div>
                                        <ContentEditable
                                            value={this.props.notes}
                                            onBlur={this.props.setNotes}
                                            placeholder={"Enter Notes.."}
                                            disabled={!this.props.editable}
                                            html={this.formatHtmlForNotes(this.props.notes)}
                                            id={"service-templates-notes"}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>                               
            </React.Fragment>
        );
    }
}

export default Information;