import React, {useState, useEffect} from "react";
import TimesheetsTable from "./TimesheetTable";

export default function JobTimesheetHeader({jobId, timesheets}){
    const [isOpen, setIsOpen] = useState(false);
    const [jobHeaderInfo, setJobHeaderInfo] = useState(null);
    const [duration, setDuration] = useState("");
    const [currentTimesheets, setCurrentTimesheets] = useState(timesheets);

    useEffect(() => { // An AJAX request to the server to request the jobHeaderInfo that will have all sorts of info we need to render this component.
        if (jobId == "" || jobId == null){
            setJobHeaderInfo({"customer_full_name": "General Time"})
        }
        else {
            $.get(
                {url: `/jobs/${jobId}.json`, 
                success: function(data){
                    console.log(data);
                    setJobHeaderInfo(data);
                }
            })
        }
    }, [])

    useEffect(() => {
        setDuration(formatDuration(totalDurationForTheseTimesheets()));
    }, [currentTimesheets])

    const totalDurationForTheseTimesheets = () => {
        const durationSum = currentTimesheets.map((timesheet) => timesheet.duration).reduce((sumSoFar, duration) => sumSoFar + duration);
        return durationSum;
    }

    return(
        <div className="card">
            <div className="d-flex justify-content-around card-header card-header-large timesheet-card-header" 
                 onClick={() => {setIsOpen(!isOpen)}}
                 style={{borderRadius: 13}}>
                <div className="p-2">
                    {jobHeaderInfo == null ? '...' : formatReadableDate(jobHeaderInfo["updated_at"])}
                </div>
                <div className="p-2">
                    <h4 className="card-header__title"> {jobHeaderInfo == null ? 'Loading ...' : jobHeaderInfo["customer_full_name"]} </h4>
                </div>
                <div className="p-2">
                    {jobHeaderInfo == null ? '...' : jobHeaderInfo["display_address"]}
                </div>
                
                <div className="p-2">
                    {duration}
                </div>
                <div className="p-2">
                    {isOpen ? <i className="fa-solid fa-chevron-up"></i> : <i className="fa-solid fa-chevron-down"></i>}
                </div>
            </div>
            <div className="card-body">
                {isOpen && <TimesheetsTable timesheets={currentTimesheets} setTimesheets={setCurrentTimesheets}/>}
            </div>
        </div>            
    )
}