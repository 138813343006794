import React, { Component } from "react";
import InvoiceTemplateDocument from "./InvoiceDocument/InvoiceTemplateDocument";

class InvoiceTemplateEditPage extends Component {
    constructor(props) {
        super(props);

        const name = props.invoice.name || '';

        this.state = {
            templateName: name,
            disableSave: false,
        }

        this.setTemplateName = this.setTemplateName.bind(this);
        this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
        this.updateDisableSave = this.updateDisableSave.bind(this);
    }

    setTemplateName(event) {
        const newTemplateName = event.target.value;
        this.setState({
            ...this.state,
            templateName: newTemplateName
        });
    }

    onSaveButtonClick(event){
        if (!this.state.disableSave){
            this.invoiceTemplateDocument.saveInvoiceTemplateToServer();
        }
        this.updateDisableSave(true);
    }

    updateDisableSave(value) {
        this.setState({
            ...this.state,
            disableSave: value,
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="text-center">
                    {
                        this.props.editable
                        ? <input id="template_name" onChange={this.setTemplateName} placeholder="Template Name Here.." value={this.state.templateName}/>
                        : <h2 id="template_name">{this.props.invoice.name}</h2>
                    }
                </div>
                <br/>
                {
                    this.props.editable && <div className="d-flex justify-content-center">
                        <div className="p-2">
                        </div>
                        <div className=" p-2 mt-4">
                            <button className="btn btn-company-primary" style={{width: "120px"}} onClick={this.onSaveButtonClick}>
                                {
                                    this.state.disableSave
                                    ? <i className="fas fa-save is-loading"></i>
                                    : 'Save Template'
                                }
                            </button>
                        </div>
                    </div>
                }
                <br/>
                <InvoiceTemplateDocument
                    {...this.props}
                    ref={(ip) => {this.invoiceTemplateDocument = ip}}
                    updateDisableSave={this.updateDisableSave}
                    templateName={this.state.templateName}/>
            </React.Fragment>
        );
    }
}

export default InvoiceTemplateEditPage;