import React, { Component } from 'react';

class TableBody extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { tableData } = this.props;

        return (
            <tbody>
            {
                tableData.map((data, index) => (
                    <tr key={data.id}>          
                        <td/>
                        <td>{ data.display_address }</td>                        
                        { this._toolsField(data) }
                    </tr>
                ))
            }
            </tbody>
        );
    }

    _toolsField(data) {
        const { permissions } = this.props;
        console.log("Permissions: " + permissions);
        if( permissions["destroy"] && permissions["update"]) {
            return (
                <td>
                    <a href={ `/jobs/${data.id}` } title="Show" style={{marginLeft : '5px'}}>
                        <i className="fas fa-eye" />
                    </a>
                    <a href={ `/jobs/${data.id}/edit` } title="Edit" style={{marginLeft : '5px'}}>
                        <i className="fas fa-pencil-alt" />
                    </a>
                    <a href={ `/jobs/${data.id}` }
                       data-method="delete"
                       data-confirm="Are you sure?"
                       title="Delete" style={{marginLeft : '5px'}}>
                        <i className="fas fa-trash" />
                    </a>
                </td>
            );
        } else if( permissions["destroy"] ) {
            return (
                <td>
                    <a href={ `/jobs/${data.id}` }
                       data-method="delete"
                       data-confirm="Are you sure?"
                       title="Delete">
                        <i className="fas fa-trash" />
                    </a>
                    <a href={ `/jobs/${data.id}` } title="Show">
                        <i className="fas fa-eye" />
                    </a>
                </td>
            );
        } else if( permissions["update"] ) {
            return (
                <td>
                    <a href={ `/jobs/${data.id}/edit` } title="Edit">
                        <i className="fas fa-pencil-alt" />
                    </a>
                    <a href={ `/jobs/${data.id}` } title="Show">
                        <i className="fas fa-eye" />
                    </a>
                </td>
            );
        } else {
            return (
                <td>
                    <a href={ `/jobs/${data.id}` } title="Show">
                        <i className="fas fa-eye" />
                    </a>
                </td>
            );
        }
    }
}

export default TableBody;