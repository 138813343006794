import React, {Component} from 'react';

class SidebarMenuItem extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const {title, path, icon} = this.props;

        return (
            <li className="sidebar-menu-item">
                <a className="sidebar-menu-button" href={path}>
                    <i className="sidebar-menu-icon sidebar-menu-icon--left material-icons">{icon}</i>
                    <span className="sidebar-menu-text">{title}</span>
                </a>
            </li>   
        );
    }
}

export default SidebarMenuItem;