import React, { Component } from "react";
import SortLink from "../shared/SortLink";

class TableHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <thead>
                <tr>
                    <th>Roster Name</th>
                </tr>
            </thead>
        );
    }
}

export default TableHeader;
