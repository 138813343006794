import React, {Component} from "react"

class AppointmentOnCard extends Component{
    constructor(props){
        super(props);
    }

    render(){
        const {appointment} = this.props;
        const appointmentTitle = appointment.appointment_type_name != "No Type" ? appointment.appointment_type_name : ""
        return(
            <div className="d-flex bd-highlight">
                <div className="p-2">
                    {appointmentTitle} {appointment.formatted_start} - {moment(appointment.end).utc().format("hh:mm a")} -- {appointment.employee_first}
                </div>
        </div>            
        )
    }
}

export default AppointmentOnCard;