import React, { Component } from "react";
import InvoiceDocument from "./InvoiceDocument/InvoiceDocument";
import Select from "react-select";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import BillToDifferentCustomer from "./BillToDifferentCustomer";

class InvoiceEditPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiceType: this.props.invoice.invoice_type || "Invoice",
            disableSave: false,
            showingDiffCust: this.props.shouldInvoiceOtherCustomer,
            selectedDiffCustOption: this.props.invoice.invoice_to ? {value: this.props.invoice.invoice_to_id, label: this.props.invoice.invoice_to.full_name} : {value: this.props.customerToInvoice?.id, label: this.props.customerToInvoice?.full_name},
        }
        this.handleInvoiceEstimateTypeSelection = this.handleInvoiceEstimateTypeSelection.bind(this);
        this.onInvoiceTemplateSelected = this.onInvoiceTemplateSelected.bind(this);
        this.onDifferentCustomerSelected = this.onDifferentCustomerSelected.bind(this);
        this.toDifferentCustomerCheckbox = this.toDifferentCustomerCheckbox.bind(this);
        this.setInvoiceType = this.setInvoiceType.bind(this);
        this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
        this.getCustomerSearchOptions = this.getCustomerSearchOptions.bind(this);
    }

    handleInvoiceEstimateTypeSelection(event){
        this.invoiceDocument.setInvoiceType(event);
    }

    onInvoiceTemplateSelected(event){
        this.invoiceDocument.updateSelectedTemplateValues(event);
    }

    onDifferentCustomerSelected(event){
        this.setState({
            ...this.state,
            selectedDiffCustOption: {value: event.customer.id, label: event.customer.full_name},
        })
        
        this.invoiceDocument.updateServiceToData(event);
    }

    toDifferentCustomerCheckbox(event){
        if(!event.currentTarget.checked){
            this.invoiceDocument.updateInvoiceTo();
        }

        this.setState({
            ...this.state,
            showingDiffCust: event.currentTarget.checked,
        })
    }

    onSaveButtonClick(event){
        if (!this.state.disableSave){
            this.invoiceDocument.saveInvoiceToServer();
        }

        this.setState({
            ...this.state,
            disableSave: true,
        })
    }

    setInvoiceType(event){
        this.setState({
            ...this.state,
            invoiceType: event.target.value
        })
    }

    getCustomerSearchOptions(input, callback){
        fetch(`/customer_data/?name=${input}`)
        .then(res => res.json())
        .then(
            (result) => {
                callback(result)
            },
            (error) => {
                console.log(error);
            }
        )
    }

    render() {
        return (
            <React.Fragment>
                <div style={{maxWidth: "800px", margin: "auto"}}>
                    <div className="d-flex justify-content-around flex-wrap">
                        <div className="p-2 bd-highlight align-middle">
                        <ToggleButtonGroup
                            color="primary"
                            value={this.state.invoiceType}
                            exclusive
                            onChange={this.handleInvoiceEstimateTypeSelection}
                            >
                            <ToggleButton value="Invoice">Invoice</ToggleButton>
                            <ToggleButton value="Estimate">Estimate</ToggleButton>
                        </ToggleButtonGroup>
                        </div>
                        <div className="p-2 bd-highlight align-middle" style={{width: "max-content"}}>
                            <Select options={this.props.invoiceTemplates}
                                onChange={this.onInvoiceTemplateSelected}
                                placeholder={"Select Template.."}
                                id="invoice_templates_outer"
                            />
                        </div>
                        <BillToDifferentCustomer
                            {...this.props}
                            toDifferentCustomerCheckbox={this.toDifferentCustomerCheckbox}
                            showingDiffCust={this.state.showingDiffCust}
                            onDifferentCustomerSelected={this.onDifferentCustomerSelected}
                            getCustomerSearchOptions={this.getCustomerSearchOptions}
                            selectedDiffCustOption={this.state.selectedDiffCustOption}
                        />
                        <div className="p-2 bd-highlight">
                        {/* onclick="$('#attach_images').trigger('click')" */}
                            <a href="##" onClick={(e) => { 
                                gritterSuccess("Coming Soon", "Uploading images directly to invoices is coming soon");
                             }} title="Attach Images" className="isDisabled">
                                <i className="fas fa-camera fa-3x"></i>
                            </a>
                            {/* <input name="attach_images" id="attach_images" type="file" className="form-control" style={{display:"none"}} /> */}
                        </div>
                        <div className="p-2 bd-highlight align-middle">
                            <a href="##" onClick={this.onSaveButtonClick} className="saveInvoiceButton" title="Save Invoice">
                            <i className={this.state.disableSave ? "fas fa-save fa-3x is-loading" : "fas fa-save fa-3x"}></i>
                            </a>
                        </div>
                    </div>
                </div>
                <InvoiceDocument
                    {...this.props}
                    ref={(ip) => {this.invoiceDocument = ip}}
                    setInvoiceType={this.setInvoiceType}
                    showServiceTo={this.state.showingDiffCust}

                    toDifferentCustomerCheckbox={this.toDifferentCustomerCheckbox}
                    showingDiffCust={this.state.showingDiffCust}
                    onDifferentCustomerSelected={this.onDifferentCustomerSelected}
                    getCustomerSearchOptions={this.getCustomerSearchOptions}
                    selectedDiffCustOption={this.state.selectedDiffCustOption}
                />
            </React.Fragment>
        );
    }
}

export default InvoiceEditPage;