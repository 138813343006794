import React, {Component} from "react"

class InvoiceOnCard extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const {invoice} = this.props


        return (
        <div className="d-flex bd-highlight">
                <div className="p-2 med-text" style={{color:this.getColor()}}>
                    {formatCurrency(invoice.total_price)}
                </div>
                <div className="p-2 med-text">
                    {invoice.short_title}
                </div>
                <div className="p-2">
                    <a href={`/invoices/${invoice.id}`}>View</a>
                </div>
        </div>
        )
    }

    getColor(){
        const {invoice} = this.props
        if (invoice.invoice_type == "Invoice"){
            if (invoice.amount_due > 0){
                return "red"
            } else {
                return "green"
            }
        } else {
            return "blue"
        }
    }
}

export default InvoiceOnCard;