import React, { Component } from "react";

class LateInvoices extends Component {
    constructor(props){
        super(props);

        this.styles = {
          a: {color: "black", float: "left", padding: 0}
        };
    }

    render(){
        const { invoices } = this.props;        

        if (invoices == null || invoices == undefined)
            return null;
            
        return (
            invoices.map((data, index) => (
                <tr className="table-danger" key={index}>
                    <td>
                      <a href={ `invoices/${data.id}` } style={ this.styles.a }>
                        { data.title }
                      </a>
                    </td>
                    <td>{data.amount_due}</td>
                    <td>{data.due_date}</td>
                </tr>
            ))
        );
    }
}

export default LateInvoices;
