import React, {Component} from "react";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";

class SimpleToggleButtonGroup extends Component{
    constructor(props){
        super(props);   

        this.state = {
            selectedValue: this.props.items[0]
        }

        this.setSelectedValue = this.setSelectedValue.bind(this);
    }

    setSelectedValue = (changeEvent) => {
        const newValue = changeEvent.target.value;
        this.setState({...this.state, selectedValue: newValue})
        if (this.props.onChange != null){
            this.props.onChange(newValue);
        }
    }

    render(){
        const {items} = this.props;
        return(
            <ToggleButtonGroup
                color="primary"
                value={this.state.selectedValue}
                exclusive
                onChange={this.setSelectedValue}>
                {items.map((item) => (
                    <ToggleButton value={item} key={item}>{item}</ToggleButton>
                ))}
            </ToggleButtonGroup>
        )
        
    }
}

export default SimpleToggleButtonGroup;