class FCTool{
    constructor(){}
    /**
     * Takes the button with id: '#submitjobbutton' and removes all of it's on click actions
     */
    static removeAllActionsFromModalButtons(){
        // $("#submitnewjob").off("click");
        $("#apt-cal-modal-customer").off("click");
        $("#apt-cal-modal-title").off("click");
    }

    /**
     * Sets the actions for the modal buttons, "View Customer", "View Job", "Navigate to Job", "Invoice Job", "Text Message", "Call"
     * @param {Id of the Job, used for setting the View Job Button} jobId 
     * @param {Id of the customer, used for setting the Text Message/Call Buttons} customerId 
     * @param {The employee job data, we'll use this to figure out which employee is going on this job so we can autofill the employee's name in our text message link} employeeJobData 
     */
    static setActionsForModalButtons(jobId, customerId, employeeJobData){
        $("#apt-cal-edit-title").click(function(event){ //Setting the "View Job" button to go to the correct job
            // if (currentBusinessName === 'Tree Removal Company, LLC.'){
            //     window.location = "/jobs/" + jobId; //temporarily keeping this the same for Utah Treeco to avoid confusion to adding a new appointment    
            // }
            // else { //Super bad and hacky. Lol. Doing this one off for a customer is not a good business practice, but oh well. I wnat o keep them happy.
                window.location = "/customers/" + customerId;
            // }
        });
    
        $("#apt-cal-modal-customer").click(function(event){ //Setting the "View Customer" button to go to the correct customer
            window.location = "/customers/" + customerId;
        });

        $('#add_appointment_button').unbind().click(function(event){
            $('#new_job_appointment_modal').modal('show'); //Show the modal for adding a new appointment
            $('#employee_job_job_id').val(jobId);
        });
        
        let assignedEmployeeName = employeeJobData["employee_name"].split(' ')[0].trim();
        if (employeeJobData["twilio_enabled"] == true){ 
            $("#text-customer-action-link-twilio").attr("href", `/messages?conversation=${employeeJobData['contacts_phone']}&content=This%20is%20${assignedEmployeeName}%20from%20${$('#home-company-name').text().trim()},%20just%20wanted%20to%20let%20you%20know%20I%27m%20on%20my%20way`);
            $("#call-customer-action-link").attr("href", `#`); //need to clear the href if it was set previous.
            
            if (currentBusinessName != 'Tree Removal Company, LLC.'){ //How do know how to set this..? For now, we're going to make it the default behavior. Let's see how Mark likes it..
                $("#call-customer-action-link").click(() => FCTool.makeOutboundCallToTwilio(modalAppointmentContext._data.customer_phone)); //it would be cool to track the recording here as well.
            }
            else {
                $("#call-customer-action-link").attr("href", `tel:${modalAppointmentContext._data.customer_phone}`); //Temporary for Utah Tree Co. Because they want to be able to call on their own phone.
            }
        }
        else {
            $("#text-customer-action-link-no-twilio").attr("href", `sms:${employeeJobData['contacts_phone']}&body=This%20is%20${assignedEmployeeName}%20from%20${$('#home-company-name').text().trim()},%20just%20wanted%20to%20let%20you%20know%20I%27m%20on%20my%20way`);
            $("#call-customer-action-link").attr("href", `tel:${employeeJobData['contacts_phone']}`);
        }
        
        //I think we already have the google_maps_link_data here
        $("#google-maps-action-link").attr("href", `${employeeJobData['google_maps_link']}`);
        $("#invoice-generation-action-link").attr("href", `/jobs/${jobId}/generate_invoice`);
    }

    static setUploadedImagesInMessageAttachmentModal(customerId) {
        const noImage = $('<div><span>No Image Found</span></div>');
        $('#photos-to-attach-to-message').empty();
        $('#photos-to-attach-to-message').append(noImage);

        if(customerId){
            $.get({
                url: `/messages/${customerId}/customer_uploaded_images.json`,
                success: function(data){
                    if(data.length > 0){
                        const imageContainer = $('<div/>');
                        $('#photos-to-attach-to-message').empty();

                        data.forEach((image)=>{
                            imageContainer.append(
                                $('<img/>', {
                                    'src': `${image['url']}`,
                                    'style': 'width: 150px  !important; height: 150px;',
                                    'class': 'm-1 btn p-0 modal-img',
                                    'data-id': `${image['id']}`,
                                    'onclick': 'imageClickHandler(event)'
                                })
                            )
                        })
                        $('#photos-to-attach-to-message').append(imageContainer);
                    }
                },
                error: function(data){
                    console.log("error");
                    console.log(data);
                }
            });
        }
    }
    //Sets the locations for the given customer on the appointment modal for the arrow to select multiple locations.
    static setLocationMenu(customerData){
        $('#location-menu').empty(); //remove the location menu children <li> elements.
        let locations = customerData["locations"];
        let jobLocation = modalAppointmentContext._data.length ? modalAppointmentContext._data["location"] : modalAppointmentContext._data
        setPropertyManageModal(locations, jobLocation?.location_id);
        // setServiceAddress(jobLocation);
        $.each(locations, function( index, value ) { //for each location, add the <li> item for the menu.
            $('#location-menu').append(`<li data-street_address='${value.street_address}'
                data-city='${value.city}' 
                data-zip_code='${value.zip_code}' 
                data-state='${value.state}' 
                data-id='${value.id}'
                data-is_default_location = '${value.is_default_location}'>
                    ${value.is_default_location ? '<i class="fa fa-map-marker" aria-hidden="true"></i>' : ""}
                    ${value.display_address}
            </li>`);
        });

        //Add the default 'New..' button.
        $('#location-menu').append(`<li data-action='New Location' data-evaluated-url=''>New..</li>`)
        
        //we need to set this onclick listener for this new li item because we added it after the modal was set
        registerContextMenuClickForNewListElements('#location-menu', setLocationMenuItemsCallback);
        
        // if there are less than 2 locations, then we should hide the bill box for UI/UX (less confusing for users not using multiple locations yet if they don't need to worry about it)
        let shouldShowBillingLocationCheckbox = locations.length > 1;
        if (shouldShowBillingLocationCheckbox){
            $("#bill_location_checkbox").show();
        } 
        else{
            $("#bill_location_checkbox").hide();
        }
    }

    static setJobLinkMenu(customerId){
        $('#link-job-menu').empty();
        //TODO: Implement for better linking. 
        // $.get({
        //     url: `/customers/${customerId}/jobs.json`,
        //     success: function(data){
        //         let jobs = data;
        //         //if length of jobs is >1, then we'll show the link field. Otherwise, we'll hide it. (Hide complexity for UI/UX experience)
        //         if (jobs.length > 1){
        //             // debugger;
        //             $.each(jobs, function(index, value) {
        //                 $("#link-job-menu").append(
        //                 $(`<li data-id='${value.id}'
        //                         data-customer_id='${customerId}'>
        //                      ${value.invoices[0].invoice_type} - ${value.invoices[0].total_price}
        //                 </li>`).data('invoices', JSON.stringify(value.invoices))
        //                        .data('notes', JSON.stringify(value.notes)));
        //             });
    
        //             registerContextMenuClickForNewListElements('#link-job-menu', setJobLinkMenuItemsCallback);
        //             $('#link-job-from-customer-div').show();
        //         }
        //         else {
        //             $('#link-job-from-customer-div').hide();
        //         }
        //     }
        // });
    }

    /**
     * Sets the messages modal on the schedule, with the customer's name and phone number, and the last 25 messages to and from this customer.
     * @param {*} customerId 
     */
    static setMessagesModal(customerData){

        //set the send text button to add a message to datatbase and on the UI when clicked.
        $('#send_text_button').unbind().click(function(){ //have to use unbind() so that the send button doesn't send multiple times because click() sets a function to be ran for each time it's called.. so it will send 1, then 2, then 3 messages, etc. for as many times as the modal has been opened. By unbinding first, we remove the previous click() action. This would be better if this was only ran once perhaps when the schedule page is loaded. We could probably move this to the schedule javascript code so that it's only ran on the page load instead of each time a modal is loaded.
            const imageSrc = $('#attached-image').attr('src')
            const file = $('#image_avatar')[0].files[0] || {}
            const uploadedImageId = $('#image_avatar_url_id').val();

            const messagePayload = {
                image: {
                    file: file,
                    imageUrl: imageSrc,
                    id: uploadedImageId
                },
                message: $('#message_to_send').val()
            }
            FCTool.addNewMessage(messagePayload);
        });

        //Clear all messages in the modal, so we have a clean slate:
        const parent = document.getElementById("chat-window-inner");
        while (parent.firstChild) {
            parent.firstChild.remove()
        }

        $('#recipient-display-name').text(customerData["phone"]);
        $('#convo-recipient-name').text(customerData["full_name"]);
        // get the phone number for the customer, and let's get the messages for this customer (latest 25) and put it in this modal.
    }

    static setMessagesModalFromPhoneNumber(phoneNumber){
        //Clear all messages in the modal, so we have a clean slate:
        const parent = document.getElementById("chat-window-inner");
        while (parent.firstChild) {
            parent.firstChild.remove()
        }        
        $.get({ //this should only be called when they click on the messaging button to message the customer.
            url: `/messages/message_thread_from_number?customer_number=${phoneNumber}`,
            success(data){
                data['messages'].forEach(function(message, index){
                    let imageUrl = data['urls'][index]
                    if (message["is_inbound"]){
                        FCTool.addMessageFromCustomer(message["content"], message["name"], moment(message["created_at"]).calendar(null, {sameElse: 'MMMM DD, hh:mm a'}), imageUrl);
                    }
                    else{
                        FCTool.addMessageFromBusiness(message["content"], message["name"], moment(message["created_at"]).calendar(null, {sameElse: 'MMMM DD, hh:mm a'}), imageUrl);
                    }
                });
                
                baguetteBox.run('.tz-gallery',{ //We have to re-enable Baguette Box so that we can actually click on the images and get the gallery.
                    "buttons":true
                });
            }
       });
    }

/*
* All the message functions that will be moved to a new messages.js javascript class/file for better organization.
*/

    /**
     * 
     * @param {*} text 
     * @param {*} name 
     * @param {*} date 
     */
    //TODO: Move this into it's own messages.js class, shouldn't be in the FCTool class.
    static addMessageFromCustomer(text, name, date, imageUrl){
        var $elem = $('#chat-window-inner'); //https://amiyasahu.github.io/create-nested-html-elements-in-jquery.html

        //We don't want to attach an image if this message didn't have an mms sent with it.
        let $imageElement = isBlank(imageUrl) ? $('<span/>') : ($('<a/>', {'class': 'lightbox', 'href' : `${imageUrl}`})
            .append($('<img/>', { 'style': 'width: 100px !important; height: 100px; object-fit: contain;',  'src': `${imageUrl}`, 'class': `${imageUrl ? 'd-block' : 'd-none'} rounded w-50` }))
        );  

        $elem.append(
            $('<div/>')
                .append($('<br>'))
                .append($('<div/>', {'class': 'yours messages'})
                    .append($imageElement)
                    .append($('<div/>', {'class': `${text ? 'd-block' : 'd-none'} hsc-message last`, 'text': `${text}`}))
                )
                .append($('<div/>', {'class': 'message-info-yours', 'text': `${date}`}))
        );
    }

    //TODO: Move this into it's own messages.js class, shouldn't be in the FCTool class.
    static addMessageFromBusiness(text, sentFromName, date, imageUrl){
        var $elem = $('#chat-window-inner'); //https://amiyasahu.github.io/create-nested-html-elements-in-jquery.html

        //We don't want to attach an image if this message didn't have an mms sent with it.
        let $imageElement = isBlank(imageUrl) ? $('<span/>') : $('<a/>', { 'class': 'lightbox', 'href' : `${imageUrl}`})
            .append( $('<img/>', { 'style': 'width: 100px  !important; height: 100px; object-fit: contain;',  'src': `${imageUrl}`, 'class': `${imageUrl ? 'd-block' : 'd-none'} rounded w-50`}));

        $elem.append(
            $('<div/>')
                .append($('<br>'))
                .append($('<div/>', {'class': 'mine messages'})
                    .append($imageElement)
                    .append($('<div/>', {'class': `${text ? 'd-block' : 'd-none'} hsc-message last`, 'text': `${text}`}))
                )
                .append( $('<div/>', {'class': 'message-info-mine', 'text': `${sentFromName}, ${date}`}))
        );
    }

    static getEmployeeJobDetailsAjax(employeeJobId, successCallback, errorCallback){
        if (!employeeJobId) //Preventing GET http://localhost:3000/employee_jobs/details/.json 404 (Not Found) error
            return;

        $.get({
            url: `/employee_jobs/details/${employeeJobId}.json`,
            success(data){
                successCallback(data);
            },
            error(data){
                errorCallback(data);
            }
        });
    }

    //when sending a new message, this is the callback for adding the new message bubble in the conversation 
    static addNewMessage(messagePayload){
        const { image: { imageUrl, file, id }, message } = messagePayload;

        if (isBlank(message) && isBlank(imageUrl)){ //Don't want to send a message if it's blank
            return;
        }

        const fd = new FormData();
        let sendTo = modalAppointmentContext._data.customer_phone;
        let businessPhoneNumber = modalAppointmentContext._data.business_twilio_number

        fd.append('file', file)
        fd.append('id', id)
        fd.append('content', message)
        fd.append('to', sendTo)
        fd.append('sent_from', businessPhoneNumber)
        fd.append('name', currentEmployeeName)

        $.ajax({
            url: '/messages.json',
            type: "POST",
            dataType: 'json',
            data: fd,
            processData: false,
            contentType: false,
            success:function(data){
                FCTool.addMessageFromBusiness(message, currentEmployeeName, moment().calendar(null, {sameElse: 'MMMM DD, hh:mm a'}), imageUrl);
                $('#message_to_send').val('');
                resetImage();
                $('#chat-window').scrollTop($('#chat-window')[0].scrollHeight);//Scroll to bottom of current convo page
            }.bind(this), //binding to 'this' so we can access 'this' from within the success callback.
            error: function(data){
                showError("Couldn't Send Message", data); //shows gritter errors
            }.bind(this)
        });
    }

    /**
     * KEEP
     * https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
     * 
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
     *
     * @returns {String}
     */
    static getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
            // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
    
        if (/android/i.test(userAgent)) {
            return "Android";
        }
    
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
    
        return "unknown";
    }    

    /**
     * Gets the aspect ratio of the width / height. 
     * Used for mobile scaling of the calendar. 
     */
    static getAspectRatio(){
        return window.innerWidth/window.innerHeight
    }

    /**
     * Gets the default calendar view. Display day if the window screen width is smaller than 800 
     * for best viewing results. 
     */
    static getDefaultView(){  
        if (Cookies.get("dispatch_view")){
            return Cookies.get("dispatch_view");
        }
        if (window.innerWidth < 800){
            return 'timeGridDay'
        }
        else {
            return 'timeGridWeek'
        }
    }

    //How to use: 
    // document.querySelector('.fc-event').append(FCTool.createBadgeElement(''));
    static createBadgeElement(content){
        let badge = document.createElement("span");
        badge.className = "appointment-message-badge";
        var textNode = document.createTextNode(content);
        badge.appendChild(textNode);
        return badge;
    }
    
    /**
     * Used to scroll the calendar to the current day in our timeline resource view
     * @param {*} calendar 
     */
    static scrollCalendarToToday(calendar){
        calendar.scrollToTime((moment()._d.getDate() - calendar.state.currentDate.getDate() + 1) * parseInt(moment().format("HH")) + ":00:00");
    }

    static didUserConfirmNotification(){ //Problem.. we have to render this on the client side where we're currently rendering the notification message on the server side.
        let notify = $('#job_notify').is(':checked'); //is the notify checkbox checked?
        if (!notify){
            return true; //if the user didn't select the notify checkbox, then we want to be able to save without notifying and sending to the customer.
        }

        let firstName = $('#job_customer_attributes_first_name').val();
        let employeeName = modalAppointmentContext._employeeName;
        let companyName = $('#home-company-name').text().trim();
        let startTime = moment(modalAppointmentContext._startDate).format('h:mm a');
        let endTime = moment(modalAppointmentContext._endDate).format('h:mm a');
        let date = moment(modalAppointmentContext._endDate).format('ddd MMM Do');

        let message = `Hi ${firstName}, this is ${employeeName} from ${companyName}, just wanted to let you know that I put you on our schedule for an appointment on ${date} between ${startTime} to ${endTime}`
        return notify && confirm(`Send text message: "${message}"?`);
    }

    static makeOutboundCallToTwilio(toPhone){
        $.post("/messages/connect_twilio_call", {
            phone: toPhone
        })
    }
}

var className = FCTool;
//https://stackoverflow.com/questions/6998355/including-javascript-class-definition-from-another-file-in-node-js
try {
    module.exports = className;
}
catch {
    console.log("Couldn't export " + className.name + "  as a seperate module.");
}
