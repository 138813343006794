import React, {Component} from 'react';
import IndexPage from '../shared/IndexPage';

class ProductsIndexPage extends Component { //this might be a good candidate for polymorphism and just inheriting from the parent IndexPage
    constructor(props){
        super(props);
        const {title, attributes, data, basePath} = this.props;

        this.state={
            data: data,
            paidDirection: 'asc'
        }

        this.updateProduct = this.updateProduct.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.handleProductPriceSorting = this.handleProductPriceSorting.bind(this);
        this.handleProductNameSorting = this.handleProductNameSorting.bind(this);
    }
    
    updateProduct(newData, index = null) {
        index || index == 0
        ? this.setState({
            ...this.state,
            data: [
                ...this.state.data.slice(0, index),
                newData,
                ...this.state.data.slice(index+1, this.state.data.length)
            ]
        })
        : this.setState({ ...this.state, data: [...this.state.data, newData]})
    }

    removeProduct(index) {
        this.setState({
            ...this.state,
            data: [
                ...this.state.data.slice(0, index),
                ...this.state.data.slice(index+1, this.state.data.length)
            ]
        })
    }

    handleProductPriceSorting(){
        const paidDirection = this.state.paidDirection;
        const updatedPaidDirection = paidDirection == 'asc' ? 'desc' : 'asc'
        const sortBy = (a, b) => paidDirection == 'asc' ? (a[3] - b[3]) : (b[3] - a[3])

        const tableData1 = [...this.state.data].sort(sortBy);

        this.setState({
            ...this.state,
            data: [...tableData1],
            paidDirection: updatedPaidDirection,
        });
    }

    handleProductNameSorting(){
        const paidDirection = this.state.paidDirection;
        const updatedPaidDirection = paidDirection == 'asc' ? 'desc' : 'asc'
        const sortBy = paidDirection == 'asc'
            ? (a, b) => (a[1].title > b[1].title ? -1 : 1)
            : (a, b) => (a[1].title < b[1].title ? -1 : 1)

        const tableData1 = [...this.state.data].sort(sortBy);

        this.setState({
            ...this.state,
            data: [...tableData1],
            paidDirection: updatedPaidDirection,
        });
    }

    render(){
        const {title, attributes, basePath} = this.props;
        const { data } = this.state;
        const openModal = () => {
            $('#import_products_services_modal').modal('show');
        }

        const importButton = (
            // here is where we'll have the import modal
            <div className="p-2">
                {/* <a href="#" onClick={openModal}> 
                    <i className="fas fa-file-import"></i>
                </a> */}
            </div>
        );


        return(
            <IndexPage
                title = {title}
                attributes={attributes}
                data={data}
                updateProduct={this.updateProduct}
                removeProduct={this.removeProduct}
                handleProductPriceSorting={this.handleProductPriceSorting}
                handleProductNameSorting={this.handleProductNameSorting}
                basePath={basePath}
                additionalTitleFragments = {importButton}
                showNewProductButton = {true}
            >

            </IndexPage>
        );
    }
}

export default ProductsIndexPage;