import React, { Component } from 'react';

class DateRangePicker extends Component {
    constructor(props){
        super(props);

        const { startDate, endDate } = this.props;
        this.state = {
            startDate: startDate || '',
            endDate: endDate || '',
            isShowButtonLoading: false
        }

        this.onStartDateChange = this.onStartDateChange.bind(this)
        this.onEndDateChange = this.onEndDateChange.bind(this)
        this.onShowClick = this.onShowClick.bind(this)
    }

    componentDidMount() {
        const { startDateId, endDateId } = this.props;
        const { startDate, endDate } = this.state
        flatpickr(`#${startDateId}`, {
            altFormat: "Y-m-d",
            onChange: this.onStartDateChange
        });
        flatpickr(`#${endDateId}`, {
            altFormat: "Y-m-d",
            onChange: this.onEndDateChange
        });
        
        if(startDate && endDate){
            SetFlatpickr(startDateId, flatpickr.parseDate(startDate, "Y-m-d" ));
            SetFlatpickr(endDateId, flatpickr.parseDate(endDate, "Y-m-d"));
        }
    }

    onStartDateChange(selectedDates, date_str, instance) {
        this.setState({ ...this.state, startDate: date_str })
    }

    onEndDateChange(selectedDates, date_str, instance) {
        this.setState({ ...this.state, endDate: date_str })
    }

    onShowClick() {
        const { path } = this.props;
        const { startDate, endDate } = this.state;
        this.setState({...this.state, isShowButtonLoading: true})

        window.location.href = `${path}?start_date=${startDate}&end_date=${endDate}`;
    }

    render() {
        const { enableToggle, startDateId, endDateId } = this.props;
        const { startDate, endDate } = this.state;
        return(
            <div id="date_range_picker"
                style={{
                    display: (enableToggle && !startDate && !endDate) ? 'none' : 'block'
                }}
            >
                <div className="d-flex justify-content-center align-items-end">
                    <div className="p-2">
                        <label htmlFor="payments_start" className="text-label invoice_type_due_label">Start Range</label>
                        <input className="flatpickr flatpickr-input form-control" type="date" id={`${startDateId}`} />
                    </div>
                    <div className="p-2">
                        <label htmlFor="payments_end" className="text-label invoice_type_due_label">End Range</label>
                        <input className="flatpickr flatpickr-input form-control" type="date" id={`${endDateId}`} />
                    </div>
                    <div className="p-2">
                        {
                            this.state.isShowButtonLoading 
                            ? 
                                <a href="##" >
                                    <i className={"fas fa-spinner-third fa-3x is-loading"}></i>
                                </a>
                            : 
                                <button
                                    onClick={this.onShowClick}
                                    id="payments_results"
                                    className="btn btn-primary"
                                    style={{ padding: '7px 10px'}}>
                                        Show
                                </button>
                        }
                        
                    </div>
                </div>
            </div>
        );
    }
}
 
export default DateRangePicker;