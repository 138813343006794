import React, {Component} from "react"
import JobIndex from "./../jobs/JobIndex"
import NavigationButton from "./navigation/NavigationButton"
import CustomerViews from "./CustomerViews"
import InvoiceIndex from "./InvoiceIndex"

class InfoPage extends Component{
    constructor(props){
        super(props);
        this.handleNavigation = this.handleNavigation.bind(this);

        this.state = {
            activeView: CustomerViews.job,
        }
    }

    render(){
        const activeView = this.getActiveView(this.state.activeView);
        return(
            <div>
                <div className="topnav d-flex justify-content-around">
                    <NavigationButton active = { this.state.activeView == CustomerViews.job ? true : false} text={"Jobs"} handleNavigation={this.handleNavigation} viewName={CustomerViews.job}/>
                    <NavigationButton active = { this.state.activeView == CustomerViews.invoice ? true : false} text={"Invoices & Estimates"} handleNavigation={this.handleNavigation} viewName={CustomerViews.invoice}/>
                    <NavigationButton active = { this.state.activeView == CustomerViews.property ? true : false} text={"Properties"} handleNavigation={this.handleNavigation} viewName={CustomerViews.property}/>
                    <NavigationButton active = { this.state.activeView == CustomerViews.contact ? true : false} text={"Contacts"} handleNavigation={this.handleNavigation} viewName={CustomerViews.contact}/>
                </div>
                <br/>
                {activeView}
            </div>
        )

    }

    /**
     * Depending on what view is selected, here is where we render the relevant views. Right now we have competing views from the app/views/shared views and the views from within our components.
     * @param {*} view 
     * @returns 
     */
    getActiveView(view){
        switch(view){
            case(CustomerViews.job):
                return <JobIndex data={this.props.jobs}/>
            case(CustomerViews.invoice):
                return <InvoiceIndex invoices={this.props.invoices}/>
            case(CustomerViews.property):
                return <div/>
            case(CustomerViews.contact):
                return <div/>
        }
        return <div/>
    }

    handleNavigation(e, data){
        this.setState({activeView: e.target.dataset.viewname})
    }
}

export default InfoPage;