import React, {Component} from "react";
import { getMobileOperatingSystem } from "../../../../../assets/javascripts/fullcalendar-helpers/FCTools";

import LineItemHeaderDesktop from "./Desktop/LineItemHeader";
import LineItemHeaderMobile from "./Mobile/LineItemHeader";

class LineItemDesktopHeader extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return(
            <React.Fragment>
                <LineItemHeaderDesktop {...this.props} addLineItem={this.props.addLineItem}/>
                <LineItemHeaderMobile {...this.props} addLineItem={this.props.addLineItem}/>
            </React.Fragment>
        );
    }
}

export default LineItemDesktopHeader;

