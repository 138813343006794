import React, {Component} from "react";

class AddressGenericModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            showDisplayAddressOptions: false,
            addressFormShow: false,
            addressFormData: {
                streetAddress:'',
                id:'',
                customerId:'',
                city:'',
                zipCode:'',
                state:'',
            }

        }
        this.handleAddressFormShow = this.handleAddressFormShow.bind(this);
        // this.addNewAddressForm = this.addNewAddressForm.bind(this);
        // this.removeNewAddressForm = this.removeNewAddressForm.bind(this);
        this.openAddressMenu = this.openAddressMenu.bind(this);
        this.editLocation = this.editLocation.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    // addNewAddressForm(event){
    //     this.setState({
    //         ...this.state,
    //         addressFormShow: true,
    //     })
    // }

    // removeNewAddressForm(event){
    //     this.setState({
    //         ...this.state,
    //         addressFormShow: false,
    //     })
    // }

    openAddressMenu(event){
        $(".address-menu").css('display','none');
        $(event.currentTarget).parent('td').find('.address-menu').toggle();
    }

    editLocation(data){
        $(".address-menu").css('display','none');

        const formData = {
            streetAddress: data?.street_address || '',
            id: data?.id || '',
            customerId: data?.customer_id || '',
            city: data?.city || '',
            zipCode: data?.zip_code || '',
            state: data?.state || '',
        }

        this.setState({
            ...this.state,
            addressFormShow: true,
            addressFormData: formData
        })
    }

    handleInputChange(event){
        const value = event?.currentTarget?.value
        const { name:dataName } = event?.currentTarget?.dataset;
        const updatedFormData = { ...this.state.addressFormData }
        updatedFormData[dataName] = value;

        this.setState({ ...this.state, addressFormData: updatedFormData })
    }

    handleAddressFormShow(showForm, data={}) {
        const { id, street_address, customer_id, city, zip_code, state } = data;
        showForm
            ? this.setState({
                addressFormShow: true,
                addressFormData: {
                    streetAddress: street_address || '',
                    id: id || '',
                    customerId: customer_id || '',
                    city: city || '',
                    zipCode: zip_code || '',
                    state: state || '',
                }
            })
            : this.setState({
                addressFormShow: false,
                addressFormData: {
                    streetAddress:'',
                    id:'',
                    customerId:'',
                    city:'',
                    zipCode:'',
                    state:'',
                }
            })
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (event.target.className == 'address-context-menu-link' || $(event.target)?.parent('ul')[0]?.className == 'custom-menu address-menu') {

        } else{
            $(".address-menu").css('display','none');
        }
    }

    render() { 
        const { genericModalShow, modalTitle, customerLocations, serviceAddress} = this.props
        const { addressFormShow, addressFormData } = this.state
        const availableCutomerLocations = customerLocations?.filter( locationData =>locationData.id != serviceAddress.id)

        return (
            <div className="modal" id="genericModal" style={{display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)"}} >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="p-2">
                                        <h2>{modalTitle}</h2>
                                    </div>
                                    <div className="p-2">
                                        {
                                            addressFormShow
                                            ? <a href="javascript:void(0)" onClick={() => this.handleAddressFormShow(false)} id="removeAddressIcon" > 
                                                <i className="fas fa-minus"></i>
                                            </a>
                                            : <a href="javascript:void(0)" onClick={() => this.handleAddressFormShow(true)} id="addAddressIcon" > 
                                                <i className="fas fa-plus"></i>
                                            </a>
                                        }
                                    </div>
                                </div>
                            </h4>
                            <a id="closeMultiAddressModal" href="javascript:void(0);" onClick={() => this.props.openAddressGenericModal(false)} ><i className="fas fa-times"></i></a>
                        </div>

                        <div className="modal-body">
                            {
                                addressFormShow
                                && <div className="row" style={{borderBottom: "2px solid rgba(0, 0, 0, 0.1)"}} >
                                    <div className="col-lg-6">
                                        <div className="p-2">
                                            <label className="text-label" htmlFor="new_location_address"> Address </label>
                                            <input
                                                onChange={(e)=>this.handleInputChange(e)}
                                                type="text"
                                                className="form-control"
                                                data-name="streetAddress"
                                                value={addressFormData.streetAddress}
                                                required
                                            />
                                            <input type="hidden" className="form-control" id="new_location_id" value={addressFormData.id} />
                                            <input type="hidden" className="form-control" id="new_customer_id" value={serviceAddress.customer_id} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-2">
                                            <label className="text-label" htmlFor="new_location_city"> City </label>
                                            <input
                                                type="text"
                                                onChange={(e)=>this.handleInputChange(e)}
                                                className="form-control"
                                                data-name="city"
                                                value={addressFormData.city}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-2">
                                            <label className="text-label" htmlFor="new_location_zip"> Zip </label>
                                            <input
                                                type="text"
                                                onChange={(e)=>this.handleInputChange(e)}
                                                className="form-control"
                                                data-name="zipCode"
                                                value={addressFormData.zipCode}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-2">
                                            <label className="text-label" htmlFor="new_location_state"> State </label>
                                            <input
                                                type="text"
                                                onChange={(e)=>this.handleInputChange(e)}
                                                className="form-control"
                                                data-name="state"
                                                value={addressFormData.state}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="p-2 text-right">
                                            <button onClick={this.props.updateAddressForm} type="button" className="btn btn-company-primary" >Save</button>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row" >
                                <div className="col-lg-12" >
                                    <table className="table mb-0 thead-border-top-0">
                                        <tbody className="list" id="selected_locations_table">
                                            <tr style={{border: 'none'}} >
                                                <td style={{border: 'none'}} >
                                                    {serviceAddress.is_default_location && <i className="fas fa-house"> </i>}
                                                    <i className="fas fa-van-shuttle"></i>
                                                </td>
                                                <td style={{border: 'none'}} >
                                                    <a
                                                        href="javascript:void(0)"
                                                        onClick={() => {
                                                            this.setState({
                                                                ...this.state,
                                                                showDisplayAddressOptions: !this.state.showDisplayAddressOptions
                                                            })
                                                        }}
                                                        title="Address"
                                                    >
                                                        {serviceAddress?.display_address}
                                                    </a>
                                                    {
                                                        this.state.showDisplayAddressOptions && <ul
                                                            className="custom-menu address-menu"
                                                            id="address-menu"
                                                            style={{display: 'block'}}
                                                        >
                                                            <li
                                                                onClick={() => this.editLocation(serviceAddress)}
                                                            >
                                                                Edit Location
                                                            </li>
                                                        </ul>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="table mb-0 thead-border-top-0">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list" id="manage_properties_table">
                                            {
                                                availableCutomerLocations.map((locationData, index) =>
                                                    <tr key={`${locationData?.id}_${index}`} >
                                                        <td>
                                                            {locationData.is_default_location && <i className="fas fa-house"> </i>}
                                                        </td>
                                                        <td>
                                                            <a
                                                                href="javascript:void(0)"
                                                                className="address-context-menu-link"
                                                                onClick={this.openAddressMenu}
                                                                title="Address"
                                                                id={`addLinks_${locationData?.id}`}
                                                            >
                                                                {locationData.display_address}
                                                            </a>
                                                            {
                                                                <ul className="custom-menu address-menu" >
                                                                    <li id={`addrMenuLinks_${locationData?.id}`}
                                                                        onClick={() => this.props.makeServiceAddress(locationData)}
                                                                    >
                                                                        Make Service Address
                                                                    </li>
                                                                    <li
                                                                        onClick={() => this.editLocation(locationData)}
                                                                    >
                                                                        Edit Location
                                                                    </li>
                                                                </ul>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default AddressGenericModal;