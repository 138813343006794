import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from 'react-select/async';

class BillToDifferentCustomer extends Component {
    constructor(props) {
        super(props);
    }

    render() { 
        const { showingDiffCust, selectedDiffCustOption } = this.props;

        return (
            <React.Fragment>
                <div className="p-2 bd-highlight align-middle" >
                    <label className="text-label" htmlFor="select_different_customer_to_invoice" >
                        <input type="checkbox" id="billToDifferentCustomer" style={{width: "auto"}} onClick={this.props.toDifferentCustomerCheckbox} checked={showingDiffCust} /> Bill To Different Customer
                    </label>
                    {
                        showingDiffCust && <AsyncSelect value={selectedDiffCustOption}
                            cacheOptions
                            loadOptions={this.props.getCustomerSearchOptions}
                            onChange={this.props.onDifferentCustomerSelected}
                            placeholder={"Select Different Customer.."}
                            id="diffCustOuter"
                        />
                    }
                </div>
            </React.Fragment>
        );
    }
}
 
export default BillToDifferentCustomer;