import React, { Component, useState } from "react";
import LegalDisclaimer from "./LegalDisclaimer";
import TotalDue from "./TotalDue";
import LineItems from "./LineItems/LineItems";
import Header from "./Header/Header";
import Information from "./Information/Information";
import { Button } from "bootstrap";
import Signature from './Signature';
import { success } from "toastr";

class InvoiceDocument extends Component {
    constructor(props) {
        super(props);

        const { invoice, handlingInvoiceTemplate, logo, customerToInvoice, serviceTo, alternateLogoFromTemplate, alternativeBusinessName, invoiceTemplateId, showServiceTo, estimateNumber, invoiceNumber } = props;
        const { line_items: lineItems, amount_paid, total_price } = invoice;
        const amountDue = handlingInvoiceTemplate ? 0 : parseFloat(total_price - amount_paid).toFixed(2) || 0.00;
        const totalAmount = parseFloat(total_price) || 0.00;
        const amountPaid = amount_paid || 0;
        const invoiceDisplayNumber = invoice.invoice_display_number || '0';

        this.state = {
          jobId: invoice.job_id,
          invoiceId: invoice.id,
          customerId: invoice.customer_id,
          invoiceDisplayNumber: invoiceDisplayNumber,
          invoiceType: invoice.invoice_type,
          customerToInvoice: customerToInvoice,
          serviceTo: serviceTo,
          sentOn: invoice.sent_on,
          notes: invoice.notes || "",
          lineItems: lineItems || [],
          amountDue: amountDue,
          amountPaid: amountPaid,
          totalPrice: totalAmount,
          issuedDate: invoice.issued_on,
          dueDate: invoice.due_on,
          handlingInvoiceTemplate: false,
          logo: logo,
          alternateLogoFromTemplate: alternateLogoFromTemplate,
          alternativeBusinessName: alternativeBusinessName,
          invoiceTemplateId: invoiceTemplateId,
          invoiceTo: invoice.invoice_to || '',
          showServiceTo: showServiceTo || this.props.shouldInvoiceOtherCustomer,
        }

        this.updateLineItems = this.updateLineItems.bind(this);
        this.setDates = this.setDates.bind(this);
        this.saveInvoiceToServer = this.saveInvoiceToServer.bind(this);
        this.setInvoiceDisplayNumber = this.setInvoiceDisplayNumber.bind(this);
        this.setNotes = this.setNotes.bind(this);
        this.setInvoiceType = this.setInvoiceType.bind(this);
    }

    // saveInvoiceTemplateToServer(){
    //   // saves line items, notes, invoice type, alternative business name, alternative business logo
    //   // Name of invoice template, etc.
    // }

    saveInvoiceToServer(){
      // const totalfiles = $('#attach_images').prop("files").length;
      const formData = new FormData();
      const { handlingInvoiceTemplate } = this.state;
      const basePath = handlingInvoiceTemplate ? 'invoice_templates' : 'invoices'; //Make basepath more generic by taking it as a props such as invoices and invoice_templates
      const url = `/${basePath}/${ this.state.invoiceId ? this.state.invoiceId : ''}`;
      const requestType = `${this.state.invoiceId ? "PUT" : "POST"}`;

      //Temporarily disable images.
      // for (let index = 0; index < totalfiles; index++) {
      //     formData.append("images[]", $('#attach_images').prop("files")[index]);
      // }

      const invoice = {
          'invoice_template_id': this.state.invoiceTemplateId,
          'id': this.state.invoiceId,
          'job_id': this.state.jobId,
          'invoice_type': this.state.invoiceType,
          'invoice_display_number': this.state.invoiceDisplayNumber,
          'issued_on': this.state.issuedDate,
          'due_on': this.state.dueDate,
          'notes': this.state.notes,
          'invoice_to_id': isBlank(this.state.invoiceTo) ? '' : this.state.invoiceTo,
          'line_items': this.state.lineItems,
      }

      formData.append('invoice', JSON.stringify(invoice))

      //Sending data as direct json in case of put request
      //else sending stringfy json where we might send images along with it
      const data = this.state.invoiceId ? {"invoice": invoice} : formData;

      //sending content type application/x-www-form-urlencoded for put request where json data is raw instead of string
      const contentType = this.state.invoiceId ? "application/x-www-form-urlencoded" : false;

      //sending process data true in case of put request and false in post request
      const processData = !isBlank(this.state.invoiceId);

      //url will be POST /invoices or POST /invoice_templates or PUT "/invoices/:id" or PUT "/invoice_templates/:id"
      //if invoiceId == null, do a POST to create new invoice, if invoiceId == not null, do a PUT to update the invoice.
      $.ajax({
          url: url,
          type: requestType,
          dataType: 'json',
          data: data,
          processData: processData,
          contentType: contentType,
          success: function(data){
              console.log("Successfully saved invoice!");
              gritterSuccess("Success", "Successfully saved invoice!");
              window.location.href = `/${basePath}/${data.id}`;
          },
          error: function(data){
              console.log("Error Ocurred");
              gritterError(`Error`, "Something went wrong");
          }
      });
  }

    render() {
        return (
            <div className="invoice-box">
              <table cellPadding={0} cellSpacing={0} id="invoice-table">
                <tbody>
                  <Header
                    {...this.props}
                    defaultLogo={this.state.logo}
                    alternateLogoFromTemplate={this.state.alternateLogoFromTemplate}
                    setDates={this.setDates}
                    invoiceType={this.state.invoiceType}
                    invoiceDisplayNumber = {this.state.invoiceDisplayNumber}
                    setInvoiceDisplayNumber = {this.setInvoiceDisplayNumber}/>

                  <Information {...this.props}
                    alternativeBusinessName = {this.state.alternativeBusinessName}
                    notes={this.state.notes} 
                    setNotes = {this.setNotes}
                    invoiceType={this.state.invoiceType}
                    customerToInvoice={this.state.customerToInvoice}
                    serviceTo={this.state.serviceTo}
                    showServiceTo={this.state.showServiceTo}
                    
                    toDifferentCustomerCheckbox={this.props.toDifferentCustomerCheckbox}
                    showingDiffCust={this.props.showingDiffCust}
                    onDifferentCustomerSelected={this.props.onDifferentCustomerSelected}
                    getCustomerSearchOptions={this.props.getCustomerSearchOptions}
                    selectedDiffCustOption={this.props.selectedDiffCustOption}
                  />

                  <LineItems
                    lineItems={this.state.lineItems}
                    updateLineItems={this.updateLineItems}
                    editable={this.props.editable}
                    productOptions = {this.props.productOptions}
                  />
                </tbody>
              </table>
              <TotalDue
                totalAmount={this.state.totalPrice}
                amountDue={this.state.amountDue}
                totalAmountPaid={this.state.amountPaid}
              />
              <hr />
              <LegalDisclaimer
                invoiceTerms = {this.props.business.invoice_terms}
                estimateTerms = {this.props.business.estimate_terms}
                invoiceType={this.state.invoiceType}
              />
              {
                this.props.invoice.signature && <Signature signatureUrl = {this.props.invoice.signature} />
              }
          </div>
        );
    }


    setNotes(event, exactValue = null){
      this.setState({
        ...this.state,
        notes: exactValue || event.target.innerText
      })
    }

    setInvoiceType(event){ //also need to change the invoice display number, replace the "I" with the "E"
      const wasSetToInvoice = event.target.value == "Invoice";
      const wasSetToEstimate = event.target.value == "Estimate";
      let newDisplayNumber = this.state.invoiceDisplayNumber;

      //Note: There used to be a bug in this code when using an "InvoiceEditPage", and when editing the invoice type from an invoice to an estimate, or vice versa. These invoiceNumber and estimateNumber props were null, which meant when the user changed the document type, the invoice display number would end up as: null-E, or null-I. This was a bug and confusing to the user. The hack/workaround is by adding invoiceNumber and estimateNumber prop arguments in InvoiceEditPage, and I just set them to be the invoice.invoice_display_number, which ended up having a -I or -E in them already. That's why we strip the number of any non-numeric characters so that we don't end up with a number like 9034-E-E. Which would also be weird behavior. Kind of a hacky workaround, but I want to get this fix out for Perform Heating and Cooling.
      if (wasSetToInvoice){

        //See above note to explain why we have to strip letters from the invoiceNumber and estimateNumber props.
        let invoiceNumberWithLetters = this.props.invoiceNumber + "" //sometimes this comes in as an integer, so we must cast to string before calling 'replace' method
        let cleanedInvoiceNumberWithoutLetters = invoiceNumberWithLetters?.replace(/\D/g, ''); //In this example, \D is a regular expression that matches any non-digit character, and the g flag specifies that the expression should be applied globally to the entire string, rather than just the first match. The replace() method replaces all matches with an empty string, effectively removing all non-numeric characters from the input string.
        newDisplayNumber =  cleanedInvoiceNumberWithoutLetters + "-I"; //TODO: there might be a bug based off of the index, if it originaly has an index, and you change to estimate, then back to invoice, it might save over the display_number without the index and cause an issue with having the same display number for 2 invoices.
      }
      else if (wasSetToEstimate){
        let estimateNumberWithLetters = this.props.estimateNumber + "" //sometimes this comes in as an integer, so we must cast to string before calling 'replace' method
        let cleanedEstimateNumberWithoutLetters = estimateNumberWithLetters?.replace(/\D/g, ''); //In this example, \D is a regular expression that matches any non-digit character, and the g flag specifies that the expression should be applied globally to the entire string, rather than just the first match. The replace() method replaces all matches with an empty string, effectively removing all non-numeric characters from the input string.
        newDisplayNumber = cleanedEstimateNumberWithoutLetters + "-E";
      }
      else {
        // do nothing?
        // This shouldn't happen because right nwo there are only 2 possible invoice types -- Invoice and Estimate
      }
      this.setState({
        ...this.state,
        invoiceType: event.target.value,
        invoiceDisplayNumber: newDisplayNumber
      })
      
      const parentPassedInFunctionToSetInvoiceType = this.props.setInvoiceType != undefined;
      if(parentPassedInFunctionToSetInvoiceType){
        this.props.setInvoiceType(event);
      }
    }

    setInvoiceDisplayNumber(event){
      this.setState({
        ...this.state,
        invoiceDisplayNumber: event.target.innerText
      });
    }

    updateLineItems(items, notes = null) {
      const priceSum = items.reduce(
        (previousValue, lineItem) => previousValue + (parseFloat(lineItem.price ? lineItem.price : 0.00) * lineItem.quantity), 0.00
      )

      const newTotalAmount = parseFloat(priceSum).toFixed(2) ? parseFloat(priceSum).toFixed(2) : 0.00;
      const newDueAmount = newTotalAmount - parseFloat(this.props.invoice.amount_paid).toFixed(2);

      this.setState({
        ...this.state,
        lineItems: [...items],
        totalPrice: newTotalAmount,
        amountDue: parseFloat(newDueAmount),
        notes: notes || this.state.notes,
      });
    }

    setDates(date, isDueDate = false) {
      isDueDate
      ? this.setState({ ...this.state, dueDate: date })
      : this.setState({ ...this.state, issuedDate: date })
    }

    updateSelectedTemplateValues(selection) {
      const { value, business_name, logo, line_items } = selection;

      this.updateLineItems([...this.state.lineItems, 
        ...line_items.map( 
          function(item) {
            return {
              id: item.id, 
              name: item.name, 
              description: item.description,
              price: item.price, 
              product_id: item.product_id, 
              quantity: item.quantity || 1} //this is to ensure there's no null quantity
          })
      ],
      selection.notes);//Hacky to tack on this notes to the updateLineItems call.
      //TODO: Add invoiceTemplateId to save onto this if it was seleted from template select.

      this.setState({
        invoiceTemplateId: value,
        alternateLogoFromTemplate: logo,
        alternativeBusinessName: business_name,
      })
    }

    updateServiceToData(selectedData){
      this.setState({
        ...this.state,
        showServiceTo: true,
        customerToInvoice: selectedData.customer,
        invoiceTo: selectedData.customer.id
      });
    }

    updateInvoiceTo(){
      this.setState({
        ...this.state,
        showServiceTo: false,
        customerToInvoice: this.state.serviceTo,
        invoiceTo: ''
      });
    }
}

export default InvoiceDocument;