import { toggleButtonGroupClasses } from '@mui/material';
import React, { Component } from 'react';

class TableBody extends Component {
    constructor(props) {
        super(props);
        

        this.onMergeCheckboxChecked = this.onMergeCheckboxChecked.bind(this);
    }

    onMergeCheckboxChecked(event){
        console.log("Merge checked with boxes: ${}");
        if (event.currentTarget.checked){
            this.props.customersToMerge.add(event.currentTarget.value);
        }
        else {
            this.props.customersToMerge.delete(event.currentTarget.value);
        }
        
        if (this.props.customersToMerge.size > 0){
            this.props.setUserWantsToMerge(true);
        }
        else {
            this.props.setUserWantsToMerge(false);
        }
        console.log(this.props.customersToMerge);
    }

    render() {
        const { tableData } = this.props;

        return (
            <React.Fragment>
                <tbody className="list" id="staff02">
                {
                    tableData.map((data, index) => (
                        <tr key={index}>
                            <td>
                                <input type="checkbox" value={data.id} onClick={this.onMergeCheckboxChecked}/>
                            </td>
                            <td>
                                <a href={`/customers/` + data.id}>
                                    <span className="js-lists-values-employee-name">
                                        { data.full_name }
                                    </span>
                                </a>
                            </td>
                            <td>{ formatPhoneNumber(data.phone) }</td>
                            <td>{ data.email }</td>                                                                     
                            { this._tools(data) }
                        </tr>
                    ))
                }
                </tbody>
            </React.Fragment>
        );
    }

    _tools(data) {
        const { tableData, permissions } = this.props;        
        if( permissions['destroy'] && permissions['update'] ) {
            return(
                <td>
                    <a href={ `/customers/${data.id}` }
                       data-method="delete"
                       data-confirm="Are you sure?"
                       title="Delete">
                        <i className="fas fa-trash" />
                    </a>
                </td>
            );
        } else if( permissions['destroy'] ) {
            return(
                <td>
                    <a href={ `/customers/${data.id}` }
                       data-method="delete"
                       data-confirm="Are you sure?"
                       title="Delete">
                        <i className="fas fa-trash" />
                    </a>
                </td>
            );
        } else if( permissions['update'] ) {
            return(
                <td>
                    <a href={ `/customers/${data.id}/edit` } title="Edit">
                        <i className="fas fa-pencil-alt" />
                    </a>
                </td>
            );
        } else {
            return(
                <td>
                </td>
            );
        }
    }
}

export default TableBody;
