import React, { Component } from "react";

class TableHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { displayTools } = this.props;

        return (
            <thead>
            <tr>
                <th>Resource</th>
                <th>Action</th>
                { this._toolsColumnHeader() }
            </tr>
            </thead>
        );
    }

    _toolsColumnHeader() {
        const { displayTools } = this.props;

        if( displayTools ) {
            return(<th />);
        } else {
            return null;
        }
    }
}

export default TableHeader;
