import React, {Component} from "react";
import IssuedDateEditable from "./IssuedDateEditable";
import IssuedDateReadOnly from "./IssuedDateReadOnly";
import InvoiceTemplateLogo from "./InvoiceTemplateLogo";

class Header extends Component {
    constructor(props){
        //Only needs the image src url for the logo, the invoice document type, the invoice number, (need to be able to set invoice number too), the date + due date.
        super(props);
    }

    componentDidMount(){
        $('#logoOption').hide();

        //Handling outside click
        $(window).click(function(event){
            const classes = event.target.classList.value;
            const isInsideClicked = classes.includes('inside')
            if(!isInsideClicked) {
                $('#logoOption').hide()
            }
        });
        
    }

    render() {
        const { defaultLogo, onLogoChange, removeLogo, editable, setDates, handlingInvoiceTemplate, alternateLogoFromTemplate} = this.props;
        const imageSrc = alternateLogoFromTemplate || defaultLogo;
        return(
            <React.Fragment>
                <tr className="top">
                    <td colSpan={editable ? 5 : 4}>
                        <table>
                            <tbody>
                                <tr>
                                    {/* This table data column is for the logo, and it should only take up 50% of the invoice document width. */}
                                    <td className="title" style={{width: "50%"}}>
                                        <div className="logo-container">
                                            <img src={imageSrc} style={{width: '100%'}} />
                                            { 
                                                handlingInvoiceTemplate
                                                && editable
                                                && <InvoiceTemplateLogo
                                                    onLogoChange={onLogoChange}
                                                    removeLogo={removeLogo}
                                                    alternateLogoFromTemplate={alternateLogoFromTemplate}
                                                />
                                            }
                                        </div>
                                    </td>
                                    <td style={{textAlign: 'right'}}>
                                        {
                                            editable && !handlingInvoiceTemplate
                                            ? <IssuedDateEditable {...this.props} setDates={setDates}/>
                                            : <IssuedDateReadOnly {...this.props}/>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>                
            </React.Fragment>
        );
    }
}

export default Header;

