import React, {Component} from "react";
import ClickableCard from "../shared/ClickableCard";
import InvoiceOnCard from "./InvoiceOnCard"
import AppointmentOnCard from "./AppointmentOnCard"


class JobCard extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const { jobData } = this.props;

        var invoiceSection = null;
        if (jobData.invoices != null && jobData.invoices.length > 0){
            invoiceSection = 
                <React.Fragment>
                    {
                        jobData.invoices.map((invoice, index) => (
                            <InvoiceOnCard invoice={invoice} key={index}/>
                        ))
                    }
                </React.Fragment>;
        }

        var appointmentSection = null;
        if (jobData.appointments != null && jobData.appointments.length > 0){
            appointmentSection = 
                <React.Fragment>
                    {
                        jobData.appointments.map((appointment, index) => (
                            <AppointmentOnCard appointment={appointment} key={index}/>
                        ))
                    }
                </React.Fragment>;
        }

        function openJobModal(){
            console.log("Job Clicked!");
        }        

        //Job Title             Job Owner
        //Address

        //Invoices...
        //Invoice amount (Red if owed, Green if paid, Blue if estimate)             Invoice Number/Name
        
        //Appointments..
        //Appointments      Appointment Type, Date, Start Time, End Time, Employee

        const jobContent = (
            <div>
                <div className="d-flex bd-highlight">
                    <div className="p-2"><h3> {jobData.job_title} </h3></div>
                    
                    { //If this job has a job owner, show their name, otherwise leave it blank.
                        jobData.owner_id != null ?

                    (<div className="p-2"><h3> {jobData.owner_full_name} </h3></div>)
                     :
                    (<div></div>)
                    }

                </div>
                <div className="d-flex bd-highlight med-text">
                    <div className="p-2">
                        {
                            jobData.location != null ? jobData.location.display_address : ""
                        }
                    </div>
                </div>
                
                {invoiceSection}

                <br/>

                {appointmentSection}
            </div>
        )
        
        return(
            <ClickableCard content={jobContent} onClick={openJobModal}></ClickableCard>
        );
    }
}

export default JobCard;