import React, {Component} from "react";

class NavigationButton extends Component{
    constructor(props){
        super(props);
    }

    // componentDidMount(){
    //     const {active, text, handleNavigation, id} = this.props;
    //     if (active){
    //         document.getElementById(id).style.visibility = "visible";
    //     }
    //     else{
    //         document.getElementById(id).style.visibility = "hidden";
    //     }
    // }

    render(){
        const {active, text, handleNavigation, viewName} = this.props;


        return (
            //How to set the job visibility in a smart way..??
            <button className={active ? "active" : ""} onClick={handleNavigation} data-viewName={viewName}>{text}</button>
        )
    }
}

export default NavigationButton;