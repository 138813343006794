import React, { Component } from 'react';

class TableBody extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { tableData } = this.props;

        return (
            <tbody>
            {
                tableData.map((data, index) => (
                    <tr>
                        <td>{ data.name }</td>
                        { this._tools(data) }
                    </tr>
                ))
            }
            </tbody>
        );
    }

    _tools(data) {
        const { permissions } = this.props;

        if( permissions["destroy"] && permissions["update"] ) {
            return(
                <td>
                    <a href={ `/groups/${data.id}` }
                       data-method="delete"
                       data-confirm="Are you sure?"
                       title="Delete">
                        <i className="fas fa-trash" />
                    </a>
                    <a href={ `/groups/${data.id}/edit` } title="Edit">
                        <i className="fas fa-pencil-alt" />
                    </a>
                    <a href={ `/groups/${data.id}` } title="Show">
                        <i className="fas fa-eye" />
                    </a>
                </td>
            );
        } else if( permissions["destroy"] ) {
            return(
                <td>
                    <a href={ `/groups/${data.id}` }
                       data-method="delete"
                       data-confirm="Are you sure?"
                       title="Delete">
                        <i className="fas fa-trash" />
                    </a>
                    <a href={ `/groups/${data.id}` } title="Show">
                        <i className="fas fa-eye" />
                    </a>
                </td>
            );
        } else if( permissions["update"] ) {
            return(
                <td>
                    <a href={ `/groups/${data.id}/edit` } title="Edit">
                        <i className="fas fa-pencil-alt" />
                    </a>
                    <a href={ `/groups/${data.id}` } title="Show">
                        <i className="fas fa-eye" />
                    </a>
                </td>
            );
        } else {
            return(
                <td>
                    <a href={ `/groups/${data.id}` } title="Show">
                        <i className="fas fa-eye" />
                    </a>
                </td>
            );
        }
    }
}

export default TableBody;
