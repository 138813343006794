import React, {Component} from "react";

class ToggleConversations extends Component {
    constructor(props){
        super(props);
    }

    render(){
        return (
            <button type="button" className="btn-company-link" onClick = {() => {
                $('#conversation-previews').show(); 
                $('#current-conversation').hide();
            }}>
                <i className="fas fa-chevron-left fa-2x"></i>
            </button>
        )
    }
}

export default ToggleConversations;