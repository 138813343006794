import React, { Component, useState } from "react"
import SimpleToggleButtonGroup from "../shared/SimpleToggleButtonGroup";
import DateRangePicker from "../shared/DateRangePicker";
import JobTimesheetHeader from "./JobTimesheetHeader";
import EmployeeTimesheetHeader from "./EmployeeTimesheetHeader";

class Index extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectedTimesheetViewType: 'Job',
        }
    }

    render(){
        const {job_timesheets, employee_timesheets, start_date, end_date} = this.props; //TODO: pass these in appropriately. 
        const job_timesheets_array = Object.entries(job_timesheets) //Triple nested array [[job_id, [{timesheet 1 hash}, {timesheet 2 hash}, etc..]]]
        const employee_timesheets_array = Object.entries(employee_timesheets) //Triple nested array [[job_id, [{timesheet 1 hash}, {timesheet 2 hash}, etc..]]]

        return(
            <React.Fragment>
                <div className="d-flex justify-content-center">
                    <div className="p-2">
                        <DateRangePicker
                            path={'timesheets'}
                            startDateId={'timesheetStartDatePicker'}
                            endDateId={'timesheetEndDatePicker'}
                            startDate={start_date}
                            endDate={end_date}
                            />

                    </div>
                </div>
                
                <div className="d-flex justify-content-center">
                    <div className="p-2">
                        <SimpleToggleButtonGroup 
                            items={['Job', 'Employee']} 
                            onChange={(newValue) => {
                                this.setState({...this.state, selectedTimesheetViewType: newValue})
                            }}/>
                    </div>
                </div>

                {/* Collapsible Section for Each Job */}
                {this.state.selectedTimesheetViewType == "Job" && job_timesheets_array.map((entry) => (
                    <JobTimesheetHeader jobId={entry[0]} timesheets={entry[1]}/>
                ))}
                {this.state.selectedTimesheetViewType == "Employee" && employee_timesheets_array.map((entry) => (
                    <EmployeeTimesheetHeader employeeId={entry[0]} timesheets={entry[1]}/>
                ))}
                {/* for each job, sorted by most recent to least recent. Then show each time card (sorted by employee alphabetically on that job), show the date, customer name, clocked in, clocked out, duration., edit pencil, delete. */}
                {/* If it's view by job toggle.. show, what? A list of the jobs, and the timesheets nested underneath them that can be edited or deleted. */}
                {/* for each employee (sorted alphabetically), show a title. Then show each time card (sorted by most recent to least recent), show the date, customer name, clocked in, clocked out, duration, edit pencil, delete. */}
                {/* If it's view by employee toggle.. show a list of the employees, and the timesheets nested underneath them (summed), that can be edited or deleted. 
                It will be a table of the employees and the timesheets will get matched to the jobs. */}                
            </React.Fragment>
        )
    }
}
export default Index;