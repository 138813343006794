import React, { Component } from 'react';
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";

class Table extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { tableData } = this.props;

        return (
            <div className="rounded-block">
                <table className="table">
                    <TableHeader />
                    <TableBody tableData={ tableData } />
                </table>
            </div>
        );
    }
}

export default Table;
