import React, {Component} from "react"
import ClickableCard from "../shared/ClickableCard";

class InvoiceCard extends Component {
    constructor(props){
        super(props);
    }

    openInvoiceModal(){
        console.log("Open Invoice Modal called");
    }

    getInvoiceAmountColor(){
        if (this.props.invoice.invoice_type == "Invoice"){
            if (this.props.invoice.amount_due > 0){
                return "red";
            }
            else {
                return "green";
            }
        }
        else{
            return "blue";
        }
    }

    getInvoiceDueDateColor(){
        let due_date = moment(this.props.invoice.due_date)
        // let today = moment.today()
        if (due_date < moment()){ //If the due date is before today....
            return "green";
        }
        else {
            return "red";
        }
        //If late, make bright red. 
        // If within 15 days of being late, make it a dark yellow color
        // Anything 15 days out, make it green.
    }

    sendInvoiceToCustomer(){
        emailInvoiceToCustomer(this.props.invoice.id);
    }

    collectInvoicePayment(){
        markAsPaid(this.props.invoice.id);
    }

    render(){
        const {invoice} = this.props;

        const cardContent = (
            <div>
                <div className="d-flex justify-content-center">
                    <div className="p-2 med-text">
                        {invoice.customer_name}
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    {invoice.display_address}
                </div>                
                <div className="d-flex justify-content-center med-text">
                    {/* TODO: make it red, green, or blue depending on status */}
                    <span style={{color: this.getInvoiceAmountColor(invoice)}}> {formatCurrency(invoice.total_price)}</span> &nbsp; &nbsp; <span>{invoice.short_title}</span>
                </div>
                <div className="d-flex justify-content-center">
                    Date: {moment(invoice.issued_on).format('MMMM d, YYYY')}
                </div>
                <div className="d-flex justify-content-center">
                    Due: &nbsp; <span style={{color: this.getInvoiceDueDateColor()}}> {moment(invoice.due_date).format('MMMM d, YYYYY')} </span>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="p-2">
                        {/* airplane */}
                        <a href="#" onClick={this.sendInvoiceToCustomer} title="Email to Customer">
                            <i className="fas fa-paper-plane fa-2x"></i>
                        </a>
                    </div>
                    <div className="p-2"> 
                        {/* edit pencil */}
                        <a href={`/invoices/${invoice.id}/edit`} title="Edit Invoice">
                            <i className="fas fa-pencil-alt fa-2x"></i>
                        </a>
                    </div>
                    <div className="p-2">
                        {/* collect payment */}
                        <a href="#" onClick={this.collectInvoicePayment} title="Mark as Paid">
                            <i className="fas fa-hand-holding-usd fa-2x"></i>
                        </a>
                    </div>
                    <div className="p-2">
                        {/* download invoice button */}
                        <a href={ `/invoices/${invoice.id}.pdf` }
                            title="Download PDF">
                            <i className="fas fa-download fa-2x" aria-hidden="true"></i>
                        </a>                        
                    </div>                                                            
                </div>
            </div>
        )
        return(
            <ClickableCard content={cardContent} onClick={this.openInvoiceModal}/>
        )
    }
}

export default InvoiceCard;