import React, { Component } from "react";

class EmailInvoiceIcon extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { invoiceId } = this.props;

        return (
            <a title="Email to Customer" href="javascript:void(0)" onClick={() => emailInvoiceToCustomer(invoiceId, this)}>
                <i className="far fa-paper-plane"></i>
            </a> 
        );
    }
}

export default EmailInvoiceIcon;
