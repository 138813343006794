import React, {Component} from "react";

class LegalDisclaimer extends Component{
    constructor(props){
        super(props)
    }

    render(){
        const { invoiceType, invoiceTerms, estimateTerms } = this.props;
        return (
            <div className="d-flex justify-content-start invoice-only-section">
              <div className="p-2">
                {
                  invoiceType == 'Invoice'
                  ? <div style={{fontSize: '11.25px'}} className="invoice-only-section" dangerouslySetInnerHTML={{ __html: invoiceTerms }} />
                  : <div style={{fontSize: '11.25px'}} className="invoice-only-section" dangerouslySetInnerHTML={{ __html: estimateTerms }} />
                }
              </div>
            </div>            
        );
    }
}

export default LegalDisclaimer;