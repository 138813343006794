import React, {useState, useEffect} from "react"
import TableDataNotEditable from "../shared/TableDataNotEditable"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Flatpickr from "react-flatpickr";

export default function TimesheetsTable({timesheets, setTimesheets}){
    const [isOpen, setIsOpen] = useState(false);
    const [activeTimesheetId, setActiveTimesheetId] = useState(0)
    const [modalTitle, setModalTitle] = useState("");
    const [clockedInTime, setClockedInTime] = useState(null);
    const [clockedOutTime, setClockedOutTime] = useState(null);

    const readableTimesheetKeys = {id: 0, customerName: 1, clockedIn: 2, clockedOut: 3, duration: 4, employeeName: 5}
    const flatpickrOptions = {
        enableTime: true,
        altInput: true, 
        altFormat: 'F j, h:i K',
        minTime: "6:00",
        maxTime: "22:00",
    }

    const editButtonClicked = (timesheetId, timesheetData) => {
        console.log("Edit button clicked! About to hit debugger");
        console.log("Time sheet Id: " + timesheetId);
        console.log("Time sheet data: ");
        console.log(timesheetData);
        setActiveTimesheetId(timesheetId);

        // let timesheetsData = timesheetsData
        let foundTimesheet = timesheetData.find(timsheet => timsheet[0] == timesheetId)

        //Why is it always indexing into 0..? That's not right. I think that's where this issue is coming from.
        setModalTitle(`${foundTimesheet[readableTimesheetKeys["customerName"]] == null ? "" : foundTimesheet[readableTimesheetKeys["customerName"]]} -- ${foundTimesheet[readableTimesheetKeys["employeeName"]]}`);
        const clockedIn = foundTimesheet[readableTimesheetKeys["clockedIn"]]
        const clockedOut = foundTimesheet[readableTimesheetKeys["clockedOut"]]
        console.log('clocked in: ' + clockedIn + ' clocked out: ' + clockedOut)
        setClockedInTime(clockedIn)
        setClockedOutTime(clockedOut)
        setIsOpen(true);
    }; //after a timesheet gets edited.. we need to modify the state somehow.

    const saveTimesheetChangesToServer = () => {//This is a closure... I don't think it will work like this (I don't think this closure gets updated)
        $.ajax({
            type: 'PUT',
            url: `/timesheets/${activeTimesheetId}.json`,
            data: {timesheet: {id: activeTimesheetId, clocked_in: moment(clockedInTime).format("YYYY-MM-DDTHH:mm:ssZ"), clocked_out: moment(clockedOutTime).format("YYYY-MM-DDTHH:mm:ssZ")}},
            success: (data) => {
                console.log("Success: " + data);
                setIsOpen(false);
                gritterSuccess("Success", "Successfully Updated Timesheet", 10000);
                const timesheetsWithEdits = [...timesheets];
                for (let index = 0; index < timesheets.length; index++){
                    const timesheet = timesheets[index];
                    const modifiedThisTimesheet = timesheet["id"] == data.timesheet.id;
                    if (modifiedThisTimesheet){
                        timesheetsWithEdits[index] = data.timesheet;
                    }
                }
                setTimesheets(timesheetsWithEdits);
            },
            error: (data) => {
                console.log("error: " + data);
                gritterError("Warning", "Couldn't Update Timesheet", 10000);
            },
        }) 
    }

    return( 
        <React.Fragment>
            <TableDataNotEditable
                basePath="timesheets"
                title={'Timesheets'}
                attributes={['Customer', 'Clocked In', 'Clocked Out', 'Duration', 'Employee']}
                data={timesheets.map((timesheet) => [
                        timesheet.id, 
                        timesheet.customer_name, 
                        timesheet.clocked_in == null ? "_" : moment(timesheet.clocked_in).format("MM/DD/YY hh:mm a"),
                        timesheet.clocked_out == null ? "_" : moment(timesheet.clocked_out).format("MM/DD/YY hh:mm a"),
                        formatDuration(timesheet.duration), 
                        timesheet.employee_name])}
                actions={{delete: true, edit: true}}
                editModalOnClick={editButtonClicked}
            />
            
            <Modal show={isOpen} onHide={() => setIsOpen(false)} centered>
                <Modal.Header>
                <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <label className="text-label invoice_type_due_label">Clocked In:</label>
                        <Flatpickr
                            value={moment(clockedInTime).format("YYYY-MM-DDTHH:mm:ssZ")}
                            data-enable-time
                            options={flatpickrOptions}
                            onChange={([date]) => {
                                console.log("datetime changed: " + date)
                                setClockedInTime(date);
                            }}
                        />

                        <label className="text-label invoice_type_due_label">Clocked Out:</label>
                        <Flatpickr
                            value={moment(clockedOutTime).format("YYYY-MM-DDTHH:mm:ssZ")}
                            data-enable-time
                            options={flatpickrOptions}
                            onChange={([date]) => {
                                console.log("datetime changed: " + date)
                                setClockedOutTime(date);
                            }}
                        />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setIsOpen(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={saveTimesheetChangesToServer}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
        )
}