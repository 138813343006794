import React, { Component } from "react";
class NewConversationButton extends Component{
    constructor(props) {
        super(props);
    }

    render(){
        const {selectNewConversationThreadCallback} = this.props;
        return(
            <button type="button" className="btn-company-link" onClick={() => selectNewConversationThreadCallback()}>
                <i className="fas fa-plus fa-2x"/>
            </button>
        )
    }
}

export default NewConversationButton;