import React, { Component } from 'react';

class SidebarSubmenuItem extends Component {
    constructor(props){
        super(props);
    }
    
    render(){
        const {title, path} = this.props;//Input Params

        return ( //TODO: we need to figure out "active" vs. "non-active" 
            <li className="sidebar-menu-item">
                <a className="sidebar-menu-button" href={path}>                
                    <span className="sidebar-menu-text">{title}</span>
                </a>
            </li>
        );
    }
}

export default SidebarSubmenuItem;
