import React, { Component } from "react";
import SearchField from "../shared/SearchField";
import QuickbooksIcon from 'images/quickbooks-assets/C2QB_green_btn_sm_hover.png' //https://stackoverflow.com/questions/46844499/how-to-handle-images-in-a-rails-webpacker-react-app

class TableTop extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (            
                <div className="d-flex bd-highlight">
                    <div className="p-3 bd-highlight">
                        { this._newLink() }
                    </div>
                    <div className="p-2 bd-highlight flex-grow-1">
                        <SearchField action="/customers" search="first_name_or_last_name_or_full_name_or_email_or_phone_or_company_cont" />
                    </div>                                                            
                    <div className="p-3 bd-highlight">
                        <a href="#">
                            <i className="fa-solid fa-ellipsis"></i>
                        </a>
                    </div>
                </div>
        );
    }

    _newLink() {
        const { permissions, _ } = this.props;

        if( permissions["create"] ) {
            return(<a href="/customers/new" title="New Customer."><i className="fas fa-plus" /></a>);
        } else {
            return null;
        }
    }

    _quickbooksIcon(){
        const { _, connectedToQuickbooks } = this.props
        if (connectedToQuickbooks){
            return (
            <a href="/quickbooks_customer_import" className="right-side" title="Import Customers From Quickbooks">
                <img src={QuickbooksIcon} className="image-fluid" width="35" height="35"></img>
            </a>)
        } else {
            return null;
        }
    }
}

export default TableTop;
