import React, { Component } from "react";
import SortLink from "../shared/SortLink";

class TableHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <thead>
                <tr>
                    <th><a href="/jobs/new"><i className="fas fa-plus fa-2x"></i></a></th>
                    <th>Address</th>
                    <th></th>
                </tr>
            </thead>
        );
    }
}

export default TableHeader;
