import React, {Component} from "react";

class TotalDue extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const { totalAmount, totalAmountPaid, amountDue } = this.props;
        return(
            <React.Fragment>
                <div className="total">
                <br />
                    <h2> 
                        <b>
                            <span style={{color: 'darkred'}} id="invoice-amount-due-label">Due: </span>
                            <span id="line-item-total">
                            { formatCurrency(amountDue) }
                            </span>
                        </b>
                    </h2>
                </div>
                <table>
                    <tbody>
                        <tr>
                        <td style={{paddingTop: '20px'}}><b> Paid: </b><span style={{color: 'green'}}> {formatCurrency(totalAmountPaid)}</span></td>
                        <td style={{paddingTop: '20px'}}>
                        <b> Original Total: </b> { formatCurrency(totalAmount) }
                        </td>
                    </tr>
                    </tbody>
                </table>    
            </React.Fragment>
        );
    }
}

export default TotalDue;

