import React, { Component } from "react";
import SortLink from "../shared/SortLink";

class TableHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <thead>
            <tr>
                <th><SortLink name="Name" url="/groups" /></th>
                <th></th>
            </tr>
            </thead>
        );
    }
}

export default TableHeader;
