import React, { Component } from "react";
import Table from "./Table";
import TableTop from "./TableTop";

class Index extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { tableData, permissions } = this.props;

        return (
            <div className="row">
                <div className="col-md-12 outer-table">
                    <TableTop permissions={ permissions } />
                    <Table tableData={ tableData } permissions={ permissions } />
                </div>
            </div>
        );
    }
}

export default Index;
