import React, {useState, useEffect} from "react";
import TimesheetsTable from "./TimesheetTable";

export default function EmployeeTimesheetHeader({employeeId, timesheets}){
    const [isOpen, setIsOpen] = useState(false);
    const [employeeHeaderInfo, setEmployeeHeaderInfo] = useState(null);
    const [duration, setDuration] = useState("");
    const [currentTimesheets, setCurrentTimesheets] = useState(timesheets);

    useEffect(() => { // An AJAX request to the server to request the employeeHeaderInfo that will have all sorts of info we need to render this component.
        if (employeeId == "" || employeeId == null){
            setEmployeeHeaderInfo({"employee_full_name": "Unassigned"})
        }
        else {
            $.get(
                {url: `/employees/${employeeId}/info`, 
                success: function(data){
                    console.log(data);
                    setEmployeeHeaderInfo(data);
                }
            })
        }
    }, [])

    useEffect(() => {
        setDuration(formatDuration(totalDurationForTheseTimesheets()));
    }, [currentTimesheets])

    const totalDurationForTheseTimesheets = () => {
        const durationSum = currentTimesheets.map((timesheet) => timesheet.duration).reduce((sumSoFar, duration) => sumSoFar + duration);
        return durationSum;
    }

    return(
        <div className="card">
            <div className="d-flex justify-content-around card-header card-header-large timesheet-card-header" 
                 onClick={() => {setIsOpen(!isOpen)}}
                 style={{borderRadius: 13}}>
                <div className="p-2">
                    {timesheets[0] == null ? '...' : formatReadableDate(timesheets[0]["clocked_in"])}
                </div>
                <div className="p-2">
                    <h4 className="card-header__title"> {employeeHeaderInfo == null ? 'Loading ...' : employeeHeaderInfo["full_name"]} </h4>
                </div>
                
                <div className="p-2">
                    {duration}
                </div>
                <div className="p-2">
                    {isOpen ? <i className="fa-solid fa-chevron-up"></i> : <i className="fa-solid fa-chevron-down"></i>}
                </div>
            </div>
            <div className="card-body">
                {isOpen && <TimesheetsTable timesheets={currentTimesheets} setTimesheets={setCurrentTimesheets}/>}
            </div>
        </div>            
    )
}