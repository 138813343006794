import React, {Component} from "react";

class Request extends Component{
    constructor(props){
        super(props);
    }

    render(){
        const {request} = this.props;
        return(
            <div className="col-md col-lg-4 draggable-el fc-draggable" 
                data-summary={request.summary}
                data-requestid = {request.id}
                data-customerid={request.customer_id}
                data-locationid={request.location_id}
                data-firstname = {request.first_name}
                data-lastname = {request.last_name}
                data-email = {request.email}
                data-phone = {request.phone}
                data-streetaddress = {request.street_address}
                data-zip = {request.zip}
                data-city = {request.city}
                data-state = {request.state}
                data-notes = {request.notes}
                data-marketingsourceid = {request.marketing_sources_id}
                data-marketingsourcename = {request.marketing_sources_name}
                onClick={jobRequestClicked}
            >

                <div className="card form-row__card bg-dark text-white">
                    <div className="card-body">
                        <div>
                            {request.first_name} {request.last_name} in {request.city}, {request.notes}
                        </div>
                        <div style={{fontSize: '80%', color: "#afafaf"}}>
                            {moment(request.created_at).calendar(null, {sameElse: 'MMMM DD, hh:mm a'})}
                        </div>
                    </div> 
                </div> 
            </div>
        )
    }
}

export default Request;