import React, { Component } from "react";
import ContentEditable from "react-contenteditable"

/**
 * The contractor who edited this recently broke the single responsibility issue and did not keep the proper level of abstraction here. 
 * So now, this component is tightly coupled with the "product" table, thus breaking our abstraction and introducing a whole host of issues. 
 * So this class should not be reused by other components until it is uncoupled and we put the correct level of abstraction back to it. 
 */
class TableData extends Component{
    constructor(props){
        super(props)

        this.addNewProduct = this.addNewProduct.bind(this);
        this.saveProductsData = this.saveProductsData.bind(this);
        this.removeProductsRow = this.removeProductsRow.bind(this);
        this.productAttrUpdate = this.productAttrUpdate.bind(this);
    }

    addNewProduct(){
        const { updateProduct } = this.props;
        const data = [
            "",
            {
                "title": "",
                "url": "products/"
            },
            "",
            0,
            ""
        ];

        updateProduct(data);
    }

    removeProductsRow(event) {
        const { removeProduct, data } = this.props;
        const { index:productTotalIndex } = $(event.currentTarget).parents('tr')?.data()
        removeProduct(productTotalIndex)
    }

    render(){
        const {title, attributes, data, basePath, actions, showNewProductButton } = this.props;
        const responsiveStyleWrapper = {
            overflowX: "auto"
        }

        var showActions = actions;
        if (showActions == null){ //These are the default actions
            showActions = {delete: true, edit: true, view: true}
        }

        const tableHeaders = this._buildTopRow(attributes);
        const tableData = this._buildTableBodyRows(data, basePath, showActions);   
        return(
            <div style={responsiveStyleWrapper}>
                <table className="table mb-0 thead-border-top-0" id={title}>
                    <thead>
                        <tr>
                        {tableHeaders}
                        <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody className="list" id="staff02">
                        {tableData}
                    </tbody>
                </table>
                {showNewProductButton 
                ? 
                    <button onClick={this.addNewProduct} className="btn btn-primary ml-4">New</button>
                : 
                    <div></div>
                }
                
            </div>
        );
    };

    _buildTopRow(attributes){
        const { handleProductNameSorting, handleProductPriceSorting } = this.props;
        const tableHeaders = [];
        for (const [index, value] of attributes.entries()){
            const titleizedValue = value.replace(/^\w/, c => c.toUpperCase());
            tableHeaders.push(
                <th key={index}>
                    {
                        value == 'name' || value == 'price'
                        ? <a
                            href="javascript:void(0)"
                            onClick={
                                value == 'name'
                                    ? () => handleProductNameSorting()
                                    : () => handleProductPriceSorting()
                            }
                            style={{
                                textDecoration: "none",
                                color: "#555555"
                            }}
                        >
                            {titleizedValue} ⇵
                        </a> 
                        : titleizedValue
                    }
                </th>
            );
        };
        return tableHeaders;
    }

    _buildTableBodyRows(data, basePath, actions){
        const tableData = [];
        for (const [index, value] of data.entries()){
            const row = [];
            const productId = value[0]; //First element is always the id
            for (const [objectIndex, objectValue] of value.entries()){
                const lookingAtObjectsId = objectIndex == 0;
                const lookingAtUrlObject = objectValue != null && objectValue["url"] != null;
                const attrName = '';
                if (lookingAtObjectsId){ //We don't want to add a <td> just for the ID.              
                    continue;
                }
                else if (lookingAtUrlObject){
                    const openInNewTab = objectValue["newtab"] != null;
                    const icon = objectValue["icon"] == null ? null : (<i className={`fas ${objectValue["icon"]}`}> {objectValue["title"]} </i>);
                    const iconFullClass = objectValue["iconclass"] == null ? null : (<i className={`${objectValue["iconclass"]}`}> {objectValue["title"]} </i>); // This extra one is because some icons don't start with 'fas', they might start with 'fab', like in the case with the stripe icon. 
                    const displayText = icon == null && iconFullClass == null ? objectValue["title"] : icon || iconFullClass
                    const linkClass = objectValue["class"] != null ? objectValue["class"] : "";
                    const hover = objectValue["hover"] != null ? objectValue["hover"] : "";
                    const target = openInNewTab ? "_blank" : "";
                    // row.push(<td key={`${objectIndex}-a`}><a href={objectValue["url"]} className={linkClass} title={hover} data-custom={objectValue["dataCustom"]} target={target}> {displayText} </a></td>)
                    row.push(<td data-index={objectIndex} key={`${objectIndex}-a`}><ContentEditable id="name" onBlur={this.productAttrUpdate} html={displayText} className="tdData" /></td>)
                }
                else {
                    if(objectIndex == 2){
                        attrName = 'description';
                    } else if(objectIndex == 3){
                        attrName = 'price';
                    } else if(objectIndex == 4){
                        attrName = 'SKU';
                    } else{
                        attrName = '';
                    }
                    
                    row.push(<td data-index={objectIndex} key={`${objectIndex}-b`} >
                        <ContentEditable id={attrName} onBlur={this.productAttrUpdate} html={(objectValue)?.toString()} className="tdData" />
                    </td>)
                }
            }
            
            row.push(
                this.actionsTd(productId, basePath, actions)
            );
            tableData.push(<tr data-index={index} id={productId} key={`${productId}_${index}`}>{row}</tr>)
        };
        return tableData;
    }

    actionsTd(productId, basePath, actions) {
        if(!productId)
        {
            return <td key={`${productId}-c`}>
                <div className="d-flex justify-content-start">
                    <button onClick={this.saveProductsData} className="btn btn-success">Create</button>
                    <button onClick={this.removeProductsRow} className="btn btn-danger ml-2">X</button>
                </div>
            </td>;
           
        }
        var viewElement = null;
        if (actions.view){
            viewElement = 
            (<div className="p-2">
                <a href={`${basePath}/${productId}`} title="View"><i className="fas fa-eye 2x"></i></a>
            </div>);  
        }
        
        var editElement = null;
        if (actions.edit){
            editElement = 
            (<div className="p-2">
                <a href={`${basePath}/${productId}/edit`} title="Edit"><i className="fas fa-pencil-alt 2x"></i></a>
            </div>);  
        }

        var deleteElement = null;
        if (actions.delete){
            deleteElement = 
            (<div className="p-2">
                <a href={`${basePath}/${productId}`} data-method="DELETE" data-confirm="Are you sure?" title="Delete"><i className="fas fa-trash 2x"></i></a>
            </div>);
        }

        return <td key={`${productId}-c`}>
            <div className="d-flex justify-content-start">
                {viewElement}
                {editElement}
                {deleteElement}
            </div>
        </td>;
    }

    productAttrUpdate(event){
        const { updateProduct } = this.props;
        const id = $(event.currentTarget).parents('tr').attr('id')
        const attr = event.currentTarget.id;
        const attrVal = event.currentTarget.innerText;
        const attrName = attr.charAt(0).toUpperCase() + attr.slice(1);
        const { index:productTotalIndex } = $(event.currentTarget).parents('tr')?.data();

        if(id != '') {
            $.ajax({
                url: `/products/${id}/update_attribute`,
                method: "PUT",
                data: {attribute: attr, value: attrVal},
                success: function(data){
                    gritterSuccess("Success", `${attrName} Updated Successfully`);
                },
                error: function(data){
                    gritterError("Error", "Something Error", "10000");
                }
            })
        }

        const newData = [...this.props.data[productTotalIndex]];
        const colIndex = $(event.currentTarget).parent('td').attr('data-index');
        colIndex == 1
            ? newData[colIndex] = {
                title: event.currentTarget.innerText,
                url: '/products'
            }
            : newData[colIndex] = event.currentTarget.innerText;

        updateProduct(newData, productTotalIndex);
    }

    saveProductsData(event) {
        const createButton = event.currentTarget;
        createButton.disabled = true;
        const { updateProduct } = this.props;

        const currentRow = $(createButton).parents('tr').children();
        
        const pName = currentRow[0].innerText;
        const pDesc = currentRow[1].innerText;
        const pPrice = currentRow[2].innerText;
        const pSku = currentRow[3].innerText;
        const { index:productTotalIndex } = $(createButton).parents('tr')?.data();

        const product = {name: pName, description: pDesc, price: pPrice, SKU: pSku}
        $.ajax({
            url: `/products.json`,
            method: "POST",
            data: {product: product},
            success: function(data){
                const newData = [
                    data.id,
                    {
                        "title": data.name,
                        "url": `products/${data.id}`
                    },
                    data.description,
                    data.price,
                    data.SKU
                ];

                updateProduct(newData, productTotalIndex);
                gritterSuccess("Success", `Product Added Successfully`);
            },
            error: function(data){
                createButton.disabled = false;
                gritterError("Error", "Something Error", "10000");
            }
        })
    }
}

export default TableData;