import React, { Component } from "react";
import CustomerSideBySide from "./CustomerSideBySide";

class CustomerMergeTool extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            baseCustomerRecord: {message: 'loading'},
            toMergeCustomerRecord: {message: 'loading'},
        }

        this.mergeRecordIntoBase = this.mergeRecordIntoBase.bind(this);
    }

    componentDidMount(){
        const { baseCustomerId, toMergeCustomerId} = this.props;
        // go fetch the customer records from the server in preparation for a merge.
        $.get({
            url: `/customers/${baseCustomerId}.json`,
            success: (data) => {
                this.setState({...this.state, isLoading: false, baseCustomerRecord: data})
            }})

        
        $.get({
            url: `/customers/${toMergeCustomerId}.json`,
            success: (data) => {
                this.setState({...this.state, isLoading: false, toMergeCustomerRecord: data})
            }})
    }

    mergeRecordIntoBase(){
        $.post({
            url: "/customers_merge", 
            data: {base_id: this.props.baseCustomerId, other_id: this.props.toMergeCustomerId}, 
            success: function(data){
                gritterSuccess("Merged Customer", "Successfully merged the customer");
            }});
    }

    render() {
        const { baseCustomerId, toMergeCustomerId } = this.props;

        return (
            <React.Fragment> 
                <div className="justify-content-center">
                    <div className="d-flex flex-row justify-content-center">
                        <br/>
                        <div className="d-flex p-2">
                            <CustomerSideBySide customerRecord={this.state.baseCustomerRecord}/>
                        {/* {JSON.stringify(this.state.baseCustomerRecord, null, 2)} */}
                        </div>
                        <div className="d-flex p-2">
                            <CustomerSideBySide customerRecord={this.state.toMergeCustomerRecord}/>
                        {/* {JSON.stringify(this.state.toMergeCustomerRecord, null, 2)} */}
                        </div>
                    </div>
                    <button onClick={this.mergeRecordIntoBase}> Merge Records! </button>
                </div>
            </React.Fragment>
            
        );
    }
}

export default CustomerMergeTool;